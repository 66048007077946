
  import { ref, defineComponent } from "vue";
  import { SendMessageDialog } from "@components";
  import {LiveViewRoutesEnum} from "@/modules/live-view/router";

  export default defineComponent({
    props: ["ticket"],

    components: {
      SendMessageDialog,
    },

    setup() {
      const displayMessageDialog = ref(false);

      const shiftRoute = LiveViewRoutesEnum.PREFIX

      return {
        displayMessageDialog,
        shiftRoute
      };
    },
  });
