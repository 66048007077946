import { Ticket } from "@/model/api/Ticket";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export declare class ITicketFinishStatus {
  $props: {
    ticket: Ticket;
  }
}

@Options({
  name: 'TicketFinishStatus',
  inheritAttrs: false,
})
export default class TicketFinishStatus extends Vue {
  @Prop() readonly ticket!: Ticket;

  get status() {
    return this.ticket?.finish_status;
  }

  get statusText() {
    return this.$t(`ticket.finish_status.${this.ticket.finish_status}`);
  }
}