import { CRUD }  from './base/crud';
import {Activity} from "@/model/api/Activity";


class ActivitiesService extends CRUD<Activity, any> {
    readonly endPoint = `activities`;

    public async autocomplete(field: string, q: string) {
        return this.get(`${this.endPoint}/field-autocomplete`, {
            params: {
                field,
                q,
            }
        });
    }

}

export const activitiesService = new ActivitiesService();



