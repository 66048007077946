import { MessageModel } from "@/model/api/Message";
import { Options, Vue } from "vue-class-component";
import {Model, Prop} from "vue-property-decorator";
import {Shift} from "@/model/api/Shift";
import moment from "moment";

@Options({})
export default class MessageEditor extends Vue {
  @Prop({default: false})
  disabled: boolean;
  
  @Prop({default: "Message:"})
  messageLabel: string;

  @Prop({default: false})
  deleting: boolean;

  @Prop({default: false})
  onlyDriversWorkingVisible: boolean;

  @Prop({default: false})
  isSendToBrokersVisible: boolean;

  @Prop()
  readonly shift: Shift;

  @Model('message')
  messageModel: MessageModel;

  get is_only_drivers_working() {
    return this.messageModel?.is_only_drivers_working;
  }

  set is_only_drivers_working(is_only_drivers_working: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_only_drivers_working
    };
  }

  get is_brokers() {
    return this.messageModel?.is_brokers;
  }

  set is_brokers(is_brokers: boolean) {

    let subject = this.messageModel.subject;
    let text = this.messageModel.text

    if(is_brokers){

      //this.messageModel.is_push = false;
      this.messageModel.is_only_drivers_working = false;

      text = `${moment(this.shift.start_datetime).format('MM/DD/YYYY hh:mma')}              
Job site: ${this.shift.job_site || '-'} 
Load site: ${this.shift.zone_load?.name}
Dump site: ${this.shift.zone_dump?.name}
Contract: ${this.shift.contract?.name}`

      if(this.shift.trucks_required_indipendent){
        subject = 'THIS IS A FIRST COME, FIRST SERVED SHIFT!'
        text = `The request is for ${this.shift.trucks_required} amount of trucks for the job:

${text}
Start Date: ${moment(this.shift.start_datetime).format('YYYY-MM-DD hh:mm A')}`
      } else {
        subject = `Trucks required ${moment(this.shift.start_datetime).format('MM/DD/YYYY hh:mma')}`;
        text += `
Driver required: {trucks_required}
The positions must be allocated in the portal or accepted in the app within 1 HOUR or it will be offered to another broker`
      }
    }


    this.messageModel = {
      ...this.messageModel,
      is_brokers,
      subject,
      text
    };
  }

  get subject() {
    return this.messageModel?.subject;
  }

  set subject(subject: string) {
    this.messageModel = {
      ...this.messageModel,
      subject
    };
  }

  get text() {
    return this.messageModel?.text;
  }

  set text(text: string) {
    this.messageModel = {
      ...this.messageModel,
      text
    };
  }

  get sendSms() {
    return this.messageModel?.is_sms;
  }

  set sendSms(is_sms: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_sms
    };
  }

  get sendEmail() {
    return this.messageModel?.is_email;
  }

  set sendEmail(is_email: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_email
    };
  }

  get sendPush() {
    return this.messageModel?.is_push;
  }

  set sendPush(is_push: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_push
    };
  }
}
