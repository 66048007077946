import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_W3Autocomplete = _resolveComponent("W3Autocomplete")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-inputgroup", {'p-error' : !_ctx.isValid}])
  }, [
    _createVNode(_component_W3Autocomplete, {
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      placeholder: "word.word.word"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_Button, {
      icon: "pi pi-map-marker",
      class: "p-button-warning",
      onClick: _ctx.emitResult
    }, null, 8, ["onClick"])
  ], 2))
}