import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_ctx.multi)
    ? (_openBlock(), _createBlock(_component_Dropdown, {
        key: 0,
        "option-value": "value",
        "option-label": "label",
        modelValue: _ctx.selectVal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectVal = $event)),
        options: _ctx.options
      }, null, 8, ["modelValue", "options"]))
    : (_openBlock(), _createBlock(_component_MultiSelect, {
        key: 1,
        modelValue: _ctx.selectVal,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectVal = $event)),
        options: _ctx.options,
        placeholder: "Select Roles",
        "option-value": "value",
        "option-label": "label"
      }, null, 8, ["modelValue", "options"]))
}