import { BaseApiFilter } from "@/model/filters/BaseApiFilter";
import { CRUD } from "@services/base/crud";
import { ref } from "vue";

/**
 * 
 * @param service HttpService with index() method
 * @param afterSelect Callback 
 * @returns 
 */
export function useAutocomplete<T>(service: CRUD<T, any>, afterSelect?: (item: T) => void) {
    const searchBox   = ref('');
    const suggestions = ref(null);
    const selected    = ref(null);

    async function search({ query }) {
        const f = new BaseApiFilter();
        f.q = query;

        const response = await service.index(f);

        suggestions.value = response.data;
    }

    async function onSelect({ value }) {
        selected.value = value; 

        if(afterSelect){
            afterSelect(value);
        }
    }

    return {
        searchBox,
        suggestions,
        selected,

        search,
        onSelect
    }
}

interface Args<T> {
    service: CRUD<T, any>; 
    params?: any;
    getQueryOpts?: () => any;
    afterSelect?: (item: T) => void;
    afterClear?: () => void;
}

export function useAutocompleteWithParams<T>(
    { service, params, getQueryOpts, afterSelect, afterClear }: Args<T>
) {
    const searchBox   = ref('');
    const suggestions = ref(null);
    const selected    = ref(null);

    async function search({ query }) {        
        let f = new BaseApiFilter();
        f.q = query;

        if (params) {
            f.filters = params;
        }
        if(getQueryOpts) {
            f = {...f, ...getQueryOpts()}
        }

        const response = await service.index(f);

        suggestions.value = response.data;
    }

    async function onSelect({ value }) {
        selected.value = value; 

        if (afterSelect) {
            afterSelect(value);
        }
    }

    async function onClear() {
        if (afterClear) {
            afterClear();
        }
    }


    return {
        searchBox,
        suggestions,
        selected,

        onClear,
        search,
        onSelect
    }
}

export function useAutocompleteAndGetDetail<T>(
    service: CRUD<T, any>, afterSelect?: (item: T) => void
) {
    const {
        searchBox,
        suggestions,
        selected,
        search,
    } = useAutocomplete(service);

    async function onSelect({ value }) {
        selected.value = await service.getById(value.id);

        if(afterSelect){
            afterSelect(selected.value);
        }
    }

    return {
        searchBox,
        suggestions,
        selected,

        search,
        onSelect
    }
}