import { Shift } from "@/model/api/Shift";
import { BaseApiFilter } from "@/model/filters/BaseApiFilter";
import { shiftsService } from "@services/shifts.service";
import { defineComponent, ref } from "vue";
import { toRefs, watch } from "vue";

export declare class IShiftAutocomplete{
    $props: {
        modelValue?: string | Shift;
        key?: string;
    };

    $emit(eventName: 'selected', value: Shift): any;
    $emit(eventName: 'update:modelValue', value: Shift): any;
}

export default defineComponent({
    name: 'ShiftAutocomplete',

    inheritAttrs: false,

    emits: ['selected', 'update:modelValue'],

    props: ['modelValue', 'shiftKey'],

    setup(props, { emit }) {
        const { modelValue } = toRefs(props);

        const shiftAutocomplete = ref(null);

        const selected    = ref(null);
        const suggestions = ref(null);

        async function search({query}) {
            const f = new BaseApiFilter();
            f.q = query;

            const response = await shiftsService.index(f);

            suggestions.value = response.data;
        }

        function onSelect(item: Shift) {
            emit('update:modelValue', props.shiftKey ? item[props.shiftKey] : item);
            emit('selected', item);
        }

        function onEnterClick(){
            shiftAutocomplete.value.$el.firstElementChild.blur()
            shiftAutocomplete.value.overlayVisible = false
        }

        watch(
          modelValue,
          () => {
            emit('update:modelValue', modelValue.value);
          },
          {
            immediate: true
          }
        );

        function selectedLabel(item: Shift) {
            return `#${item.id} - ${item.code}`;
        }

        function getTitle({ zone_load, zone_dump}: Shift) {
            const [
                {name: loadName},
                {name: dumpName}
            ] = [zone_load, zone_dump];

            return `LoadSite: ${loadName}\nDumpSite: ${dumpName}`
        }

        return {
            selected,
            suggestions,
            search,
            onSelect,
            selectedLabel,
            onEnterClick,
            shiftAutocomplete,
            getTitle
        }
    }
});