import { BaseApiModel, IBaseApiModel } from "../common/BaseApiModel"
import { TicketFinishStatusEnum, TicketStatusEnum } from "../enums/TicketStatusEnum";
import timezone, { Moment } from "moment-timezone";
import { Transform } from "class-transformer";
import { User } from "./User";
import { Shift } from "./Shift";
import { Contract } from "@/model/api/Contract";
import { PaymentTypeEnum } from "@/model/enums/PaymentTypeEnum";
import { PaymentStatusEnum } from "@/model/enums/PaymentStatusEnum";

export class Ticket extends BaseApiModel {
  user_id?: number;
  user?: User;

  shift_id?: number;
  shift?: Shift;

  avg_cycle?: number;
  avg_drive_to_load?: number;

  free_dump_accepted_at?: Date;
  free_dump_requested_at?: Date;
  free_dump_note?: string;

  files?: any[]

  broker_deduct?: number;
  broker_deduct_note?: string;

  contract_id?: number;
  contract?: Contract;
  status?: TicketStatusEnum;

  fee_driver?: number;
  fee_system?: number;
  fee_customer?: number;
  fee_broker?: number;
  fee_client?: number;

  cycles?: number;
  total_ton?: number;
  system_warning?: number;
  ticket_day?: string;
  finish_status?: TicketFinishStatusEnum;
  finish_note?: string;
  dispute_note?: string;
  rate?: number;
  extra_fee_driver?: number;
  extra_fee_broker?: number;
  extra_fee_customer?: number;
  extra_fee_system?: number;

  avg_load?: number;

  payment_status?: PaymentStatusEnum


  /**
   * TimeZone
   */
  zone_name?: string;

  @Transform(
    ({ value, obj }) => timezone.tz(value, obj.zone_name),
    { toClassOnly: true }
  )
  @Transform(
    ({ value, obj }) => value.tz(obj.zone_name, true)
      .clone().tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
    { toPlainOnly: true }
  )
  datetime_start?: Moment;

  @Transform(
    ({ value, obj }) => timezone.tz(value, obj.zone_name),
    { toClassOnly: true }
  )
  @Transform(
    ({ value, obj }) => value.tz(obj.zone_name, true)
      .clone().tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
    { toPlainOnly: true }
  )
  datetime_end?: Moment;

  @Transform(
    ({ value, obj }) => timezone.tz(value, obj.zone_name),
    { toClassOnly: true }
  )
  @Transform(
    ({ value, obj }) => value.tz(obj.zone_name, true)
      .clone().tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
    { toPlainOnly: true }
  )
  datetime_assigned?: Moment;

  mov_id?: number;
  customer_id?: number;
  broker_id?: number;
  client_id?: number;

  private_closeout_note?: string;
}


export interface TicketHistory extends IBaseApiModel {
  ticket_id: number;

  lat: number;
  lng: number;

  note: string;

  ton: number;
  original_ton?: number;
  ton_image_path: string;

  status: TicketStatusEnum;
}

export interface CalculatedFee {
  cycles: number;
  fee_driver?: number;
  fee_system?: number;
  fee_customer?: number;
  fee_broker?: number;
  fee_client?: number;
  payment_type: number;
}
