import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, _mergeProps(_ctx.$attrs, {
    completeOnFocus: "",
    modelValue: _ctx.searchBox,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchBox = $event)),
    suggestions: _ctx.suggestions,
    dropdown: _ctx.dropdown,
    field: "name",
    minLength: 2,
    placeholder: _ctx.$t(`zone_autocomplete.placeholder`),
    onItemSelect: _ctx.onSelect,
    onComplete: _ctx.search,
    onClear: _ctx.onClear
  }), {
    item: _withCtx(({item}) => [
      _createElementVNode("span", null, _toDisplayString(item.name), 1)
    ]),
    _: 1
  }, 16, ["modelValue", "suggestions", "dropdown", "placeholder", "onItemSelect", "onComplete", "onClear"]))
}