import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { Shift } from '@/model/api/Shift';
import { plainToClass } from 'class-transformer';
import { CRUD }  from './base/crud';
import {toRaw} from "@vue/reactivity";


class ShiftsService extends CRUD<Shift, any> {
    readonly endPoint = `shifts`;

    public async index(params?: any): Promise<PaginatedResponse<Shift>> {
        const response = await super.index(params);

        response.data = plainToClass(Shift, response.data);
        response.data.forEach((shift: Shift) => {
            (shift as any).original_dates = {
                start_datetime: shift.start_datetime,
                end_datetime: shift.end_datetime,
                expire_at: shift.expire_at
            }
            shift.updateDates()
        })
        return response;
    }

    public async getById(id: number): Promise<Shift> {
        const response = await super.getById(id);
        const classShift = plainToClass(Shift, response)
        classShift.updateDates()
        return classShift;
    }

    public exportExcel(params?: any): Promise<string>{
        // TODO Attendere API 
        // if (filters) {
        //     Object.keys(filters).forEach(k => {
        //         const v = filters[k].value; 
        //         const n = filters[k].nullable; 
    
        //         if (!v && !n) {
        //             delete filters[k];
        //         }
        //     });

        //     if (!Object.keys(filters)?.length) {
        //         filters = null; 
        //     }
        // }

        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'arraybuffer',
            params: { }
        })
    }

    public async autocomplete(field: string, q: string) {
        return this.get(`${this.endPoint}/field-autocomplete`, {
         params: {
             field,
             q,
         }
        });
    }

    public async getTicketsHistory(shiftId: number) {
       return this.get(`${this.endPoint}/${shiftId}/ticketsHistory`);
    }

    public async prepareByActivityId (activityId: string | number) {
        const response = await this.get(`${this.endPoint}/prepareByActivityId/${activityId}`);
        const classShift = plainToClass(Shift, response);
        classShift.updateDates();
        return classShift;
    }

    public async getShiftAvg(id: string | number) {
        return this.get(`${this.endPoint}/${id}/updateAvgByShiftId`);
    }

    public async disable(id: Number) {
        return this.post(`${this.endPoint}/${id}/disable` );
    }
}

export const shiftsService = new ShiftsService();



