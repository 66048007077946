import { Options, Vue }     from "vue-class-component";
import { Prop }             from "vue-property-decorator";
import { RouteLocationRaw } from "vue-router";

export declare class IPageHeader{
  $props: {
    title           : String;
    saveDisabled?   : Boolean;
    deleteDisabled? : Boolean;
    showDelete?     : Boolean;
    noBack?         : Boolean;
    backRoute?      : RouteLocationRaw;
  }
  
  $emit(eventName: 'onSave')   : any;
  $emit(eventName: 'onDelete') : any;
}

@Options({
  emits: ['onSave', 'onDelete']
})
export default class PageHeader extends Vue {
  @Prop() 
  readonly title!: String;

  @Prop() 
  readonly saveDisabled!: Boolean;
  
  @Prop() 
  readonly deleteDisabled!: Boolean;
  
  @Prop({default: true}) 
  readonly showDelete!: Boolean;

  @Prop({ default: false })
  readonly noBack!: Boolean;

  @Prop() 
  readonly backRoute!: RouteLocationRaw;

  onSave(){
    this.$emit('onSave');
  }

  onDelete(){
    this.$emit('onDelete');
  }
}