import moment from "moment";
import { Moment } from "moment";
import { Options, Vue } from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

@Options({ })
export default class MomentZoneCalendar extends Vue {

  @Model('moment') momentValue: Moment; 

  @Model('date') dateValue: Date;

  @Prop() max: Moment;

  @Prop() min: Moment;

  @Prop({required: true}) timezone: string; 


  get calendarDate(): Date {
    if (this.dateValue) {
      return this.dateValue;
    } else if (this.momentValue) {
      return this._momentToDate(this.momentValue)
    }
    return null;
  }

  set calendarDate(value: Date) {
    const m = moment(value); 

    this.momentValue = m.tz(this.timezone, true);

    this.dateValue = value; 
  }

  get maxMomentToDate() {
    if (!this.max || !this.max.isValid()) return null;

    return new Date(
        this.max.format("YYYY-MM-DDTHH:mm:ss.00")
    );
  }

  get minMomentToDate() {
    if (!this.min || !this.min.isValid()) return null;

    return new Date(
        this.min.format("YYYY-MM-DDTHH:mm:ss.00")
    );
  }

  private _momentToDate(v: Moment) {
    if (!v.isValid()) return null;

    return new Date(
      v.format("YYYY-MM-DDTHH:mm:ss.00")
    );
  }
}