import { defineComponent, ref } from "vue";

export default defineComponent({
    inheritAttrs: false,

    emits: ['selectedAddress', 'update:modelValue'],

    props: ['modelValue'],

    setup(_, { emit }) {
        const selectedAddress    = ref(null);
        const addressSuggestions = ref(null);

        const autocompleteService = new google.maps.places.AutocompleteService();
        const geocoder            = new google.maps.Geocoder();

        async function searchAddress({ query: input }) {
            const response = await autocompleteService.getPlacePredictions({
                input,
                componentRestrictions: {
                    country: 'it',
                },
            });

            addressSuggestions.value = response.predictions;
        }

        function onSelect(item: google.maps.places.AutocompletePrediction) {
            const placeId = item.place_id;

            geocoder.geocode({ placeId }, (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        emit('update:modelValue', item.description);
                        emit('selectedAddress', results[0]);
                    }
                }
            });
        }

        return {
            selectedAddress,
            addressSuggestions,
            searchAddress,
            onSelect,
        }
    }
});