import {BaseApiModel} from "@/model/common/BaseApiModel";
import {UserRoleEnum} from "../enums/UserRoleEnum";
import {UserStatusEnum} from "../enums/UserStatusEnum";
import { Company } from "./Company";
import {Contract} from "./Contract";
import {State} from "./State";

/* 
Le due foto sono 
avatar e truck 
*/
export class User extends BaseApiModel {

    name?       : string;
    surname?    : string;
    fullname?   : string;
    email?      : string;
    phone       : string;

    isLoading?: boolean;

    enable_self_accept: boolean;

    customer    : any
    customer_id : number

    licenseFileToUpload?: File;
    insuranceFileToUpload?: File;
    avatarFileToUpload?: File;
    truckFileToUpload?: File;

    client      : any
    client_id   : number

    broker      : any
    broker_id   : number

    reg_broker  : string
    reg_customer: string

    role?       : UserRoleEnum;
    status      : UserStatusEnum; 

    is_news_mail_active : boolean;
    is_news_push_active : boolean;
    is_news_sms_active  : boolean;

    company_name: string;

    state_id             : number;
    state                : State; 
    
    city                 : string;
    address              : string;
    zip                  : string;
    truck_information    : string;
    tag_information      : string;
    license_number       : string;

    license_image_path     : string;
    license_image_path_url : string;

    insurance_image_path     : string;
    insurance_image_path_url : string;

    avatar_image_path     : string;
    avatar_image_path_url : string;
    
    truck_image_path     : string;
    trucks_required      : number;
    truck_image_path_url : string;

    contract_id          : number;
    contract             : Contract;

    // Campi aggiunti il 21/09/21 
    // https://whimsical.com/pdf-BpJLXxS1pBnkDe3iZGE1Dx

    cdl_license     : string;

    usdot_number    : string;

    emergency_name   : string;
    emergency_phone : string;
    emergency_email : string;

    truck_tag       : string;
    libera_id       : string;

    note_private    : string;

    is_user_free_dump_req? : boolean

    company?: Company;
    company_id?: number;

    // ------------------------

    //Utili in edit
    password?           : string;
    confirmPassword?   : string;

    // ------------------------

    // TEMP

    whitelisted?     : boolean

    constructor() {
        super();
        this.role = UserRoleEnum.DRIVER
        this.status = UserStatusEnum.ACTIVE
    }
}
