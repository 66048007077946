import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {FilterMatchMode} from "primevue/api";
import UserAutocomplete from "@/components/UserAutocomplete/UserAutocomplete.vue";

@Options({
  components: {
    UserAutocomplete
  }
})
export default class AssignNotificationDialog extends Vue {
  @Prop()
  notification: any;

  adminSelected: any = null;
  toMyself: boolean = true;

  private close() {
    this.$emit("update:visible", false);
    this.$emit('update', this.toMyself ? null : this.adminSelected.id);
  }

  get usersQueryOpts(){
    return {
      sortField: 'name',
      sortOrder: 1,
      per_page: 100,
      page: 1
    }
  }

  get userFilters(){
    return {
      "role":{
        "value": UserRoleEnum.SUPER_ADMIN,
        "matchMode": FilterMatchMode.EQUALS
      }
    }
  }

  toMyselfSelected(){
    this.adminSelected = null;
  }

  onAdminSelected(){
    this.toMyself = false;
  }

  onAssign(){
    this.close()
  }

  show(){
    this.adminSelected = null;
    this.toMyself = true;
  }

}
