import { mixins, Options } from "vue-class-component";

import { CRUD } from "@services/base/crud";

import Table from "./Table";
import { Prop } from "vue-property-decorator";

export declare class IAppTable {
    $props: {
        service           : CRUD<any, any>;
        filtersSchema?    : any;
        stateKey?         : string;
        deletMsgFn?       : (data) => string;
        deleteSuccessMsg? : string;
        deleteErrorMsg?   : string;
    }


    $emit(eventName: 'edit')         : any;
    $emit(eventName: 'delete')       : any;
    $emit(eventName: 'resetFilters') : any;
}

@Options({})
export default class AppTable extends mixins(Table) {
    @Prop({ default: true })
    readonly showHeader: boolean;
}