import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "image-wrapper" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    style: _ctx.dialogStyle,
    minX: 0,
    minY: 0,
    header: "Ton photo"
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          icon: "pi pi-refresh",
          class: "rotate-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rotateImg()))
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.src,
            style: _normalizeStyle({ transform: 'rotate(' + _ctx.rotation + 'deg)' }),
            ref: "image",
            onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.adjustDialogContentSize && _ctx.adjustDialogContentSize(...args)))
          }, null, 44, _hoisted_3)
        ])
      ])
    ]),
    _: 1
  }, 16, ["style"]))
}