import { Zone }         from '@/model/api/Zone';
import { ZoneFile } from '@/model/api/ZoneFile';
import { plainToClass } from 'class-transformer';
import { CRUD }         from './base/crud';

class ZonesService extends CRUD<Zone, any> {
    readonly endPoint = `zones`;

    public getById(id: number): Promise<Zone> {
        return this.view(id, true);
    }

    public getByIdNoShapes(id: number): Promise<Zone> {
        return this.view(id, false);
    }

    private async view(id: number, withShapes: boolean){
        let url = `${this.endPoint}/${id}`; 

        if(withShapes){
            url += `?with_shapes=true`;
        }

        const response = await this.get<Zone>(url);
        return plainToClass(Zone, response);
    }

    uploadFiles(zoneId: number, file) {
        const formData = new FormData();
        
        formData.append("file", file);
        formData.append("type", "IMG");

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<ZoneFile>(`${this.endPoint}/${zoneId}/upload`, formData, options);
    }

    deleteFile(fileId: number) {
        return this.delete(`zone-files/${fileId}`);
    }
}

export const zonesService = new ZonesService();



