import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = { class: "p-mt-2" }
const _hoisted_3 = {
  key: 0,
  class: "p-mb-3"
}
const _hoisted_4 = { class: "p-d-flex p-jc-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_UserAutocomplete = _resolveComponent("UserAutocomplete")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    header: "Add Ticket"
  }, _ctx.$attrs), {
    footer: _withCtx(() => [
      (_ctx.requestPending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ProgressBar, {
              mode: "indeterminate",
              style: {"height":".5em"}
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          label: "Cancel",
          class: "p-button-info",
          icon: "pi pi-times",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:visible', false))),
          disabled: _ctx.requestPending
        }, null, 8, ["disabled"]),
        _createVNode(_component_Button, {
          type: "button",
          label: "Save",
          loading: _ctx.requestPending,
          onClick: _ctx.save
        }, null, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FloatLabel, { label: "Truck Number" }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              modelValue: _ctx.truck_number,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.truck_number = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UserAutocomplete, {
          modelValue: _ctx.user,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.user = $event)),
          params: _ctx.userFilters
        }, null, 8, ["modelValue", "params"])
      ])
    ]),
    _: 1
  }, 16))
}