import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { CalculatedFee, Ticket, TicketHistory } from '@/model/api/Ticket';
import { TicketStatusEnum } from '@/model/enums/TicketStatusEnum';
import { classToPlain, plainToClass } from 'class-transformer';
import axios, { CancelTokenSource } from 'axios';
import { CRUD } from './base/crud';
import {ZoneFile} from "@/model/api/ZoneFile";
import {removeNullValues} from "@/utils/utils";

class TicketsService extends CRUD<Ticket, any> {
    readonly endPoint = `tickets`;

    public async index(params?: any): Promise<PaginatedResponse<Ticket>> {
        const response = await super.index(params);

        response.data = plainToClass(Ticket, response.data);

        return response;
    }

    public uploadFiles(ticketId: number, file) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("type", "IMG");

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<ZoneFile>(`${this.endPoint}/${ticketId}/upload`, formData, options);
    }

    public deleteFile(fileId: number) {
        return this.delete(`tickets-files/${fileId}`);
    }

    public async getById(id: number): Promise<Ticket> {
        const response = await super.getById(id);

        return plainToClass(Ticket, response);
    }

    public deleteBySystem(item: Ticket): Promise<Ticket> {
        return this.delete<Ticket>(
            `${this.endPoint}/by_system/${item.id}`
        );
    }

    public deleteStandard(item: Ticket): Promise<Ticket> {
        return this.delete<Ticket>(
            `${this.endPoint}/${item.id}`
        );
    }

    public history(id: number): Promise<TicketHistory[]> {
        return this.get<TicketHistory[]>(`${this.endPoint}/${id}/history`)
            .then(response =>
                response.sort((a, b) =>
                    new Date(a.created_at).valueOf()
                    - new Date(b.created_at).valueOf()
                )
            );
    }

    public setStatus(
        id: number, 
        status: TicketStatusEnum,
        ticket: Ticket
    ) {
        const plainTicket: Ticket = classToPlain(ticket);

        return this.post(`${this.endPoint}/${id}/set-status`, 
            {
                status,
                datetime_start : plainTicket.datetime_start,
                datetime_end   : plainTicket.datetime_end,
                cycles         : plainTicket.cycles,
                finish_note    : plainTicket.finish_note,
                total_ton    : plainTicket.total_ton,
            }
        );
    }

    public editTicketHistoryTon(id: number, ton: number){
        return this.post(`${this.endPoint}/updateHistory/${id}`, {ton});

    }

    public patchFee(id: number, fee: number) {
        return this.patch(`${this.endPoint}/${id}`, {fee});
    }

    public calcFeeSource: CancelTokenSource = null

    public calcFee(ticket: Ticket): Promise<CalculatedFee> {
        this.calcFeeSource?.cancel()
        this.calcFeeSource = axios.CancelToken.source()
        if(!ticket.datetime_start.isValid()){
            ticket.datetime_start = null
        }
        const plainTicket: Ticket = classToPlain(ticket);
        
        return this.post(`${this.endPoint}/${ticket.id}/calculate-fee`, 
            {
                datetime_start          : plainTicket.datetime_start,
                datetime_end            : plainTicket.datetime_end,
                contract_id             : plainTicket.contract_id,
                total_ton               : plainTicket.total_ton || null,
                cycles                  : plainTicket.cycles,
                extra_fee_driver        : plainTicket.extra_fee_driver || null,
                broker_deduct           : plainTicket.broker_deduct || null,
                extra_fee_broker        : plainTicket.extra_fee_broker || null,
                extra_fee_system        : plainTicket.extra_fee_system || null,
                extra_fee_customer      : plainTicket.extra_fee_customer || null
            }, { cancelToken: this.calcFeeSource.token }
        );
    }

    public createAndClose(payload, shift_id){
        removeNullValues(payload)
        return this.post(`${this.endPoint}/create-and-close`, {
            ...payload,
            shift_id
        })
    }

    public uploadTicketHistoryImage(id, {image}){
        const formData = new FormData();

        if (image) {
            formData.append("image", image);
        }

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return this.post<any>(`${this.endPoint}/updateHistory/${id}`, formData, options);
    }

    public acceptFreeDumpRequest(ticketId: number) {
        return this.post(`${this.endPoint}/${ticketId}/acceptFreeDump`)
    }

    public exportFile(filters?: any, type?: string, withImages?: boolean): Promise<string>{
        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k].value; 
                const n = filters[k].nullable; 

                if (!v && !n) {
                    delete filters[k];
                }
            });

            if (!Object.keys(filters)?.length) {
                filters = null; 
            }
        }

        if(type === 'invoice'){
            return this.get<string>(`${this.endPoint}/export-invoice`,  {
                responseType: 'arraybuffer',
                params: { filters, type: 'excel' }
            })
        }
        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'arraybuffer',
            params: { filters, type, withImages : withImages }
        })
    }
}

export const ticketsService = new TicketsService();



