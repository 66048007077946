import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class FileInput extends Vue {
  @Prop()
  readonly modelValue: File; 

  @Prop()
  readonly url: string; 
  
  private theFile: File = null; 

  get file() {
    return this.theFile;
  }

  set file(file: File) {
    this.theFile = file; 
    this.$emit('update:modelValue', this.file);
  }

  get fileUrl() {
    if (this.file) {
      return URL.createObjectURL(this.file)
    } else {
      return this.url; 
    }
  }

  get id() {
    return (this.$attrs as any).id;
  }
  
  onFileChange(event: Event) {
    this.file = (event.target as HTMLInputElement).files[0];
    this.$emit('select', this.file);
  }

  removeFile() {
    (this.$refs.fileInput as HTMLInputElement).value = null;
    this.file = null; 
  }
}
