
  import { defineComponent, computed } from "vue";
  import * as api            from "@what3words/api";
  import { configuration }   from "@plugins/Config-plugin";
  import { W3Autocomplete }  from "@components";

  export default defineComponent({
    inheritAttrs: false,

    components: { W3Autocomplete },

    emits: ["result", "update:modelValue"],

    props: ["modelValue"],

    setup(_, { emit }) {
      api?.setOptions({ key: configuration.w3wKey });

      const isValid = computed(() => {
        return api.valid3wa(_.modelValue);
      });

      function emitResult() {
        let text =
          typeof _.modelValue === "string" ? _.modelValue : _.modelValue?.words;

        api.convertToCoordinates(text).then((data) => {
          emit("result", data);
        });
      }

      return {
        isValid,
        emitResult,
      };
    },
  });
