import {Shift, ShiftStatusEnum} from "@/model/api/Shift";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {shiftsService} from "@services/shifts.service";
import {FilterMatchMode} from "primevue/api";
import {Options, Vue} from "vue-class-component";

import {AppTable, DuplicateShiftDialog, SendMessageDialog} from "@components";
import moment from "moment";
import Table from "@components/AppTable/Table";
import {whitelistUsersService} from "@services/whitelist_users.service";
import {classToPlain} from "class-transformer";
import {AccessesBySection, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {authStore} from "@/modules/auth/store";
import {RouteLocationRaw} from "vue-router";

@Options({
  components: {
    AppTable,
    DuplicateShiftDialog,
    SendMessageDialog
  }
})
export default class ShiftsTable extends Vue {
  filters: any = null;

  showDuplicateDialog: boolean = false;
  selectedShift: Shift = null;

  get table() {
    return this.$refs.dataTable as Table;
  }

  get canDuplicate(){
    return AccessesBySection.WRS_EDIT.includes(authStore.getters.me.role)
  }

  onClone(shift: Shift) {
    this.selectedShift = shift;

    this.showDuplicateDialog = true;
  }

  onDuplicateCancel() {
    this.showDuplicateDialog = false;
  }

  updateDates(res){
    ['shift_datetime', 'start_datetime', 'end_datetime', 'expire_at', 'broker_status_at', 'shift_day'].forEach((key) => {
      if(res[key]){

        if(key === 'shift_day'){
          res[key] = moment(res[key]).format('YYYY-MM-DD')
        } else {
          const input = moment(res[key]).format()
          const fmt   = "YYYY-MM-DDTHH:mm:ss.000000\\Z";
          const zone  = res.zone_name;

          const m = moment.tz(input, fmt, zone);

          m.utc();

          res[key] = m.format(fmt)
        }
      }
    })
  }

  async onDuplicateConfirm(clone: Shift) {
    try {
      const plainShift = classToPlain(clone);
      if(!plainShift.trucks_required_indipendent){
        plainShift.trucks_required = 0
      }
      this.updateDates(plainShift)
      const cloned = await shiftsService.create(plainShift as any);
      const shiftWhitelist = await whitelistUsersService.getUsers({
        shift_id: this.selectedShift.id
      })

      const promises = []

      shiftWhitelist.forEach(async (broker: any) => {
        promises.push(await whitelistUsersService.addUser({
          shift_id: cloned.id,
          broker
        }))
      })

      Promise.all(promises).then(async () => {
        await this.$successMessage(this.$t('shift.duplicated_message'));

        // this.table.applyFilter();

        this.showDuplicateDialog = false;

        await this.$router.push({
          name: ShiftsRoutesEnum.SHIFTS_DETAIL,
          params: { shiftId: cloned.id }
        })
      }).catch((err) => {
        if(err) this.$errorMessage(this.$t('Operation failed'))
      });
    } catch (error) {
      if(error) this.$errorMessage(this.$t('Operation failed'))
    }

  }

  get service() {
    return shiftsService;
  }
  
  getRowClass({ trucks_engaged, trucks_min, trucks_required, status }: Shift) {
    if (status === ShiftStatusEnum.DISABLED) {
      return "shift_disabled";
    } else if (status === ShiftStatusEnum.TO_BE_APPROVED) {
      return "shift_tobeapp";
    }
    
    if(trucks_min == 0)
      trucks_min = 1;
    
    if (trucks_engaged >= trucks_required) {
      return "truck_required";
    } else if ( 
      trucks_engaged <= trucks_required && 
      trucks_engaged >= trucks_min 
    ) {
      return "truck_min";
    }

    return null; 
  }

  goToDetail(shift: Shift) {
    const route: RouteLocationRaw = {
      params: { shiftId: shift.id }
    }
    if(authStore.getters.me.role === UserRoleEnum.FOREMAN){
      route.name = ShiftsRoutesEnum.SHIFTS_KPI
    } else {
      route.name = ShiftsRoutesEnum.SHIFTS_DETAIL
    }
    this.$router.push(route)
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      shift_day: {
        value: moment().format("YYYY-MM-DD"),
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  created() {
    this.initFilter();
  }
}
