import { Zone } from "@/model/api/Zone";
import { ZoneTypeEnum } from "@/model/enums/ZoneTypeEnum";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ZoneCard extends Vue {
  @Prop() readonly zone!: Zone;
  @Prop() readonly zoneTitle!: string;

  get isDumpZone() {
    return this.zone?.zone_type === ZoneTypeEnum.DUMP
  }
  get icon() {
    if (!this.zone) return "";

    return this.isDumpZone
      ? "fa-poop"
      : "fa-caret-square-up"
  }
}