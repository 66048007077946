/**
 * 10	utente creato
 * 20	attivo
 * 30	disabilitato (no login)
 * 40	l'utente si è disattivato da app (per apple) (no login)
 */
export enum UserStatusEnum {
    CREATED  = 10,
    ACTIVE   = 20,
    DISABLED = 30,
    DISABLED_APP = 40
}