
  import { defineComponent, toRefs, watch } from "vue";
  import { useAutocompleteWithParams } from "@/use/useAutocomplete";
  import { usersService } from "@services/users.service";
  import { User } from "@/model/api/User";

  export default defineComponent({
    name: "UserAutocomplete",

    inheritAttrs: false,

    emits: ['select'],

    props: ["modelValue", "showStatus", "params", "queryOpts"],

    setup(_, { emit }) {
      const { modelValue, params, queryOpts } = toRefs(_);

      const { onSelect, search, searchBox, suggestions } =
        useAutocompleteWithParams<User>({
          service: usersService,

          params: params ? params.value : {},

          getQueryOpts: () => {
            return queryOpts ? queryOpts.value : {};
          },

          afterSelect: (user: User) => {
            emit("select", user);
          },
        });

      function onChange(e: Event) {
        const v = (e.target as HTMLInputElement).value;
        if (!v) {
          emit("select", null);
        }
      }

      function getField(user: User) {
        return `${user.name} ${user.surname}`;
      }

      watch(
        modelValue,
        () => {
          searchBox.value = modelValue.value;
        },
        {
          immediate: true,
        }
      );

      return {
        searchBox,
        suggestions,

        onChange,
        onSelect,
        search,
        getField
      };
    },
  });
