import { User } from '@/model/api/User';
import { CRUD } from './base/crud';
import {PaginatedResponse} from "@/model/api/PaginatedResponse";
class UsersDriversService extends CRUD<User, any> {
    readonly endPoint = `users/drivers`;

    public index(args?: any){

        const params      = this.getCleanParams(args);
        const cancelToken = this.generateCancelToken();

        return this.get<PaginatedResponse<any>>(
            `${this.endPoint}`,
            { params, cancelToken }
        );
    }
}

export const usersDriversService = new UsersDriversService();



