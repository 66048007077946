import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {ticketsService} from "@services/tickets.service";

export default class DeductDialog extends Vue {

  @Prop()
  driver: any;

  broker_deduct: number = 0;
  broker_deduct_note: string = "";

  async onEdit() {
    this.$waitFor( async () => {
      /*if(this.broker_deduct > 0 && !this.broker_deduct_note){
        this.$errorMessage("broker deduct note is required");
        return
      }*/
      await ticketsService.updatePatch({
        id: this.driver.ticketId,
        broker_deduct: this.broker_deduct,
        broker_deduct_note: this.broker_deduct_note
      });
      this.$successMessage("Ticket successfully updated");
      this.$emit("onChange", {
        broker_deduct: this.broker_deduct,
        broker_deduct_note: this.broker_deduct_note
      });
      this.close();
    });
  }

  onShow(){
    this.broker_deduct = this.driver.broker_deduct
    this.broker_deduct_note = this.driver.broker_deduct_note
  }
 
  private close() {
    this.$emit("update:visible", false);
  }

}
