
  import { defineComponent, ref, toRefs, watch } from "vue";
  import { contractsService } from "@services/contracts.service";
  import { Contract } from "@/model/api/Contract";

  export default defineComponent({
    name: "ContractAutocomplete",

    inheritAttrs: false,
    emits: ['select'],
    props: [
      "modelValue",
      "contractId",
      "customerId",
      "forceSelection",
      "label",
      "noDropdown"
    ],

    setup(_, { emit }) {
      const { modelValue, customerId } = toRefs(_);

      const options = ref<Contract[]>([]);

      const searchBox   = ref<string | Contract>("");
      const suggestions = ref(null);
      const selected    = ref(null);

      async function search({ query }) {

        const text = (query as string).toLowerCase().trim();

        if(customerId){
          suggestions.value = await contractsService.getByCustomerId(customerId.value, text);
        } else {
          suggestions.value = options.value?.filter((c) =>
              c.name.toLowerCase().includes(text)
          );
        }
      }

      async function onSelect({ value }) {
        selected.value = value;
        emit("select", value);
      }

      async function onClear() {
        searchBox.value = null
        emit("select", null);
      }

      watch(
        modelValue,
        () => {
          searchBox.value = modelValue.value;
        },
        {
          immediate: true,
        }
      );
      
      function selectedLabel(item: Contract) {
        return `${item.name}`;
      }

      return {
        searchBox,
        suggestions,
        onClear,
        onSelect,
        search,
        selectedLabel
      };
    },
  });
