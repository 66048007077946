import axios, { CancelTokenSource }  from "axios";
import { HttpService }               from './base/http.service';
import { State }                     from "@/model/api/State";
import { PaginatedResponse }         from "@/model/api/PaginatedResponse";

class DomainService extends HttpService {

    private readonly endPoint = ``;
    
    protected source: CancelTokenSource;

    public states(q: any){

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        return this.get<PaginatedResponse<State>>(
            `${this.endPoint}/states`, 
            { 
                params: { q },
                cancelToken
            }
        );
    }

    // public cities(params: any) {
    //     if (!params){
    //         params = (new BaseApiFilter());
    //     }

    //     this.source = axios.CancelToken.source();
    //     const cancelToken = this.source.token;

    //     return this.get<PaginatedResponse<any>>(
    //         `${this.endPoint}/cities`, 
    //         { 
    //             params,
    //             cancelToken
    //         }
    //     );
    // }

    // public provinces(params: any) {
    //     if (!params){
    //         params = (new BaseApiFilter());
    //     }

    //     this.source = axios.CancelToken.source();
    //     const cancelToken = this.source.token;

    //     return this.get<PaginatedResponse<City>>(
    //         `${this.endPoint}/cities`, 
    //         { 
    //             params,
    //             cancelToken
    //         }
    //     );
    // }
}

export const domainService = new DomainService();



