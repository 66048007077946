import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { plainToClass } from 'class-transformer';
import { CRUD } from './base/crud';
import { InvoiceMovement } from "@/model/api/Invoice";

class InvoiceMovementsService extends CRUD<InvoiceMovement, any> {
    readonly endPoint = `inv-movements`;

    public async index(params?: any): Promise<PaginatedResponse<InvoiceMovement>> {
        const response = await super.index(params);

        response.data = plainToClass(InvoiceMovement, response.data);

        return response;
    }

    public async downloadInvoices(id: number, invoice_type: string = null) {
        return this.post<any>(`${this.endPoint}/${id}/download-invoices`, {
            invoice_type
        }, { responseType: 'arraybuffer' }, true);
    }

    public async calculate(payload): Promise<any[]> {
        return this.post<any>(`${this.endPoint}/calculate`, payload);
    }

}

export const invoiceMovementsService = new InvoiceMovementsService();



