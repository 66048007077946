
import { defineComponent } from "vue";
import { UserStatusEnum }  from "@/model/enums/UserStatusEnum";

export default defineComponent( {
  name: "UserStatusSelect",
  props: ["modelValue"],
  data() {
    return {
      options: [
        {
          value: UserStatusEnum.CREATED,
          label: 'CREATED'
        },
        {
          value: UserStatusEnum.ACTIVE,
          label: 'ACTIVE'
        },
        {
          value: UserStatusEnum.DISABLED,
          label: 'DISABLED'
        }
      ]
    }
  },
  computed: {
    selectVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }

} )
