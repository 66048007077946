import {defineComponent, ref} from "vue";
import {shiftsService} from "@services/shifts.service";
import {Shift} from "@/model/api/Shift";

export default defineComponent({
    inheritAttrs: false,

    emits: ['selected', 'update:modelValue'],

    props: ['modelValue', 'field'],

    setup(_, { emit }) {
        const suggestions = ref(null);

        async function search({ query: input }) {

            const response: any = await shiftsService.autocomplete(_.field, input);

            suggestions.value = response;

        }

        function onSelect(item: Shift) {
            emit('update:modelValue', item[_.field]);
            emit('selected', item);
        }

        return {
            suggestions,
            search,
            onSelect,
        }
    }
});