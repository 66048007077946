import { Company } from '@/model/api/Company';
import { CRUD } from './base/crud';

class CompaniesService extends CRUD<Company, any> {
    readonly endPoint = `companies`;


    public async getCompanyCustomers(companyId: number): Promise<any[]> {
        return this.get<any[]>(`${this.endPoint}/${companyId}/customers`);
    }

    public async addCustomerToCompany(customer_id: number, companyKey: string, company_id: number): Promise<any[]> {
        return this.post<any[]>(`${this.endPoint}/customers`, {customer_id, [companyKey]: company_id});
    }
    public async removeCustomerFromCompany(customer_id: number, companyKey: string, company_id: number): Promise<any[]> {
        return this.delete<any[]>(`${this.endPoint}/customers`, {customer_id, [companyKey]: company_id});
    }

    public exportFile(filters?: any, type?: string): Promise<string>{
        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k].value;
                const n = filters[k].nullable;

                if (!v && !n) {
                    delete filters[k];
                }
            });

            if (!Object.keys(filters)?.length) {
                filters = null;
            }
        }

        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'arraybuffer',
            params: { filters, filename: type }
        })
    }

    uploadFiles(userId: number, files: UploadFiles) {

        const {
            logo, doc
        } = files;

        const formData = new FormData();

        if (logo) {
            formData.append("file", logo);
            formData.append("type", "logo");
        }


        if (doc) {
            formData.append("file", doc);
            formData.append("type", "doc");
        }

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`${this.endPoint}/${userId}/upload`, formData, options);
    }

}

interface UploadFiles {
    logo: File,
    doc: File
}

export const companiesService = new CompaniesService();
