import Table from "../Table";

import { mixins, Options }       from "vue-class-component";
import { CRUD }          from "@services/base/crud";
import {Prop} from "vue-property-decorator";

export declare class IDynamicTable {
    $props: {
        cols                : any[];
        service             : CRUD<any, any>;
        filtersSchema?      : any;
        stateKey?           : string;
        deletMsgFn?         : (data) => string;
        deleteSuccessMsg?   : string;
        deleteErrorMsg?     : string;
    }
}

@Options({
    name: "DynamicTable",
    props: ['cols'],
})
export default class DynamicTable extends mixins(Table) {

    getComponent(component){
        if (typeof component === "string"){
            return `vue:${component}`;
        } else {
            return component; 
        }
    }

    getFieldData(item, field: string){
        let data = item;
        const keys = field.split(".");
        for (let i = 0; i < keys.length; i++){
            data = data?.[keys[i]];
        }
        return data;
    }
}