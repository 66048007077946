import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Options({
  name: 'TicketStatus',
  inheritAttrs: false
})
export default class TicketStatus extends Vue {
  @Prop() readonly status!: TicketStatusEnum;

  @Prop() readonly is_free_dumped!: boolean;

  ticketStatusEnum(){
    return TicketStatusEnum
  }

  get statusText() {
    return this.$t(`ticket.statuses.${TicketStatusEnum[this.status]}`);
  }
}