import { Options, Vue } from "vue-class-component";

import MessageEditor from "@/components/MessageEditor/MessageEditor.vue";
import { MessageModel } from "@/model/api/Message";
import { Prop } from "vue-property-decorator";
import { Shift } from "@/model/api/Shift";
import { shiftsService } from "@services/shifts.service";
import { messageService } from "@services/message.service";

@Options({
  components: {
    MessageEditor
  }
})
export default class ShiftDeleteDialog extends Vue {
  @Prop()
  shift: Shift; 

  message: MessageModel = new MessageModel();

  async onDelete() {
    const message = this.$t('shift.delete_message');
    const response = await this.$confirmMessage(message);

    if (response) {
      this.$waitFor(
        async () => {
          await this.deleteAndSendMessage(this.shift);
          this.close();
          this.$emit("deleted");
        },

        this.$t('shift.delete_message_failed') as string 
      );
    }
  }
  
  private close() {
    this.$emit("update:visible", false);
    this.$emit('update');
  }
  
  private async deleteAndSendMessage(shift: Shift) {
    return this.$waitFor(
      async () => {
        // await shiftsService.deleteBySystem(shift);

        await shiftsService.disable(shift.id);
        if((this.message.is_email || this.message.is_push || this.message.is_sms) && this.message.text){
          this.message.shift_id = shift.id;
          this.message.is_only_drivers_working = true;
          await messageService.send(this.message)
        }

        // TODO: I18n 
        this.$successMessage("Shift successfully deleted")
      },
      
      this.$t('shift.delete_message_failed') as string 
    )
  }
}
