import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {KPIMessageTypes} from "@/model/enums/KPIMessageTypes";
import {messagesService} from "@services/messages.service";

@Options({
  components: {}
})

export default class ShiftKPIDialog extends Vue {
  @Prop()
  shiftId: number;
  @Prop()
  action: KPIMessageTypes;

  subject: string = '';
  text: string = '';

  async onSend() {
      this.$waitFor(
          async () => {
            await messagesService.sendShift(this.shiftId, {
              message_type: this.action,
              message: this.text,
              title: this.subject
            })
            this.$successMessage("Message sent")
            this.close();
          },

          this.$t('Operation failed')
      );
  }

  onShow() {
    this.text = `WRS #${this.shiftId}`
    switch (this.action){
      case KPIMessageTypes.CHANGE_ZONE:
        this.subject = 'New Load/Dump zone change request'
        this.text += ` New Load/Dump`
        break;
      case KPIMessageTypes.CHANGE_QUANTITY:
        this.subject = 'Change WRS truck quantity request'
        this.text += ` Need change the quantity to:`
        break;
      case KPIMessageTypes.CANCEL_TRUCKS:
        this.subject = 'Cancel trucks request'
        this.text += ` Cancel truck`
        break;
    }
  }

  private close() {
    this.$emit("update:visible", false);
    this.$emit('update');
  }
}
