import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.selectedMaterial,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedMaterial = $event)),
    options: _ctx.materials,
    showClear: _ctx.showClear,
    optionLabel: "name",
    placeholder: "Select Material",
    optionValue: "id"
  }, null, 8, ["modelValue", "options", "showClear"]))
}