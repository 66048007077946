import { BaseApiModel } from "@/model/common/BaseApiModel";
import {Company} from "@/model/api/Company";

export enum InvoiceTypeEnum {
    CLIENT = "CL",
    BROKER = "BR",
    SYSTEM = "SY",
    CUSTOMER = "CU",
    DRIVER = "DR"
}

export class InvoiceMovement extends BaseApiModel {
    tot_fee_broker: number
    tot_fee_customer: number
    tot_fee_driver: number
    tot_fee_system: number
    tot_price_client: number
    client: Company
    from_period_at: Date
    to_period_at: Date
}