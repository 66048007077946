import { Options, Vue } from "vue-class-component";

import MessageEditor from "@/components/MessageEditor/MessageEditor.vue";
import { User } from "@/model/api/User";
import { DynamicTable } from "@components";
import { UsersRoutesEnum } from "@/modules/users/router";
import { FilterMatchMode } from "primevue/api";
import { whitelistUsersService } from "@services/whitelist_users.service";
import Table from "@components/AppTable/Table";
import { Prop, Watch } from "vue-property-decorator";
import { CompanyTypeEnum } from "@/model/enums/CompanyTypeEnum";
import { companiesService } from "@services/companies.service";
import { CompaniesRoutesEnum } from "@/modules/companies/router";
import { authStore } from "@/modules/auth/store";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  emits: ['addUser', 'removeUser'],
  components: {
    MessageEditor,
    DynamicTable
  }
})

export default class WhitelistUserDialog extends Vue {

  @Prop()
  customerId: number;

  @Watch('customerId')
  private onCustomerChange(val) {
    if (val) {
      this.filters.customer_id = {
        value: this.customerId,
        matchMode: FilterMatchMode.EQUALS
      }
    } else {
      delete this.filters.customer_id
    }
  }

  filters: any = null;

  private cachedList: User[] = [];

  get service() {
    return companiesService;
  }

  get cols() {
    return [
      {
        field: 'name',
        header: "Name",
        placeHolder: "Name contains"
      },
      {
        field: 'email',
        header: "Email"
      },
      {
        field: 'phone',
        header: "Phone"
      },
      {
        field: 'business_name',
        header: "Business name"
      },
      {
        field: 'business_address',
        header: "Business address"
      },
      {
        field: 'business_city',
        header: "Business city"
      },
      {
        field: 'business_country',
        header: "Business country"
      }
    ] as {
      field?: keyof User,
      [key: string]: any
    }[]
  }

  get canGoBroker() {
    return [UserRoleEnum.SUPER_ADMIN].includes(authStore.getters.me.role);
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      type: {
        value: CompanyTypeEnum.BROKER,
        matchMode: FilterMatchMode.EQUALS
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      business_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      phone: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      business_city: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      business_country: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      'customer.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    };
  }

  async created() {
    this.initFilter();
  }

  async onShowDialog() {
    this.cachedList = [...await whitelistUsersService.getUsers()]
  }

  isWhitelisted(user: User) {
    return this.cachedList.find((cU) => user.id === cU.id)
  }


  async onRemove(user: User) {
    user.whitelisted = false
    this.cachedList = this.cachedList.filter((wUser) => wUser.id !== user.id);
    (this.$refs.table as Table).refresh()
    this.$emit("removeUser", user);
  }

  async onAdd(user: User) {
    user.whitelisted = true
    const existingUser = this.cachedList.find((cU) => user.id === cU.id)
    if (!existingUser) {
      this.cachedList.push(user)
      this.$emit("addUser", user);
    }
    (this.$refs.table as Table).refresh()
  }

  async onShow(broker: User) {
    if (!this.canGoBroker) {
      return
    }

    const routeData = this.$router.resolve({
      name: CompaniesRoutesEnum.BROKER_DETAIL, params: { companyId: broker.id }
    });
    window.open(routeData.href, '_blank');
  }

  private close() {
    this.$emit("update:visible", false);
    this.$emit('update');
  }

}
