<template>
  <Fieldset class="p-mb-3">
    <template #legend>
      <div class="p-d-flex">
        <Skeleton
          height="1rem"
          width="6rem"
        />
      </div>
    </template>

    <div class="p-formgroup-inline">
      <div class="p-field">
        <Skeleton
          height="2rem"
          width="12rem"
        />
      </div>
      <div class="p-field">
        <Skeleton
          height="2rem"
          width="12rem"
        />
      </div>
      <div class="p-field">
        <Skeleton
          height="2rem"
          width="70px"
        />
      </div>
      <div class="p-field">
        <Skeleton
            height="2rem"
            width="12rem"
        />
      </div>
      <div class="p-field">
        <Skeleton
            height="2rem"
            width="12rem"
        />
      </div>
    </div>

  </Fieldset>
</template>