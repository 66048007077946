import { CRUD }                from './base/crud';
import {PaginatedResponse} from "@/model/api/PaginatedResponse";

class MessagesService extends CRUD<any, any> {
    readonly endPoint = `notification-center`;

    public async index(params?: any): Promise<PaginatedResponse<any>> {
        const response = await super.index(params);
        response.data.forEach((message) => {
            message.oldStatus = message.status
        })
        return response
    }

    sendShift(shiftId: number, payload) {
        return this.post(`${this.endPoint}/shifts/${shiftId}/send`, payload);
    }

    unreadCounter(){
        return this.get<any>( `${ this.endPoint }/unread-count` );
    }

    read(id){
        return this.post<any>( `${ this.endPoint }/${id}/read` );
    }

    changeStatus(id, status){
        return this.post<any>( `${ this.endPoint }/${id}/changeStatus`, {
            status
        });
    }

    changeOwner(id, user_id){
        return this.post<any>( `${ this.endPoint }/${id}/own`, {user_id} );
    }

    readAll(){
        return this.post<any>( `${ this.endPoint }/read-all` );
    }
}

export const messagesService = new MessagesService();



