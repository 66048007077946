import moment from "moment";
import { PaymentTypeEnum } from "../enums/PaymentTypeEnum";
import { Path, Zone } from "./Zone";
import timezone from "moment-timezone";
import { Contract } from "@/model/api/Contract";
import { Material } from "@/model/api/Material";
import { Company } from "./Company";
import {Project} from "@/model/api/Project";
import {Activity} from "@/model/api/Activity";
import {User} from "@/model/api/User";

export class Shift {
	id: number;
	custom_shift_id?: number;
	code: string;
	description: string;
	job_site: string;
	contract_id: number;
	contract: Contract;
	cost_code: string;
	material_id: number;
	material: Material;

	foreman: User;
	foreman_id: number;

	project: Project;
	activity: Activity;
	project_id: number;
	activity_id: number;

	trucks_required_indipendent: boolean

	po_number: number;

	can_upload_photo_load: boolean
	can_upload_photo_dump: boolean

	is_expire_active: boolean;

	is_broker_status_at: boolean;
	broker_status_at: Date;

	is_private: boolean;
	private_contract_id: number;
	private_contract: Contract;

	is_dump_same_load_site: boolean
	is_free_dump: boolean


	load_address: string
	load_city: string
	load_state: any
	load_zip: string

	dump_address: string
	dump_city: string
	dump_zip: string

	zone_load_id: number;
	zone_load: Zone;
	load_lat: number;
	load_lng: number;

	zone_dump_id: number;
	zone_dump: Zone;
	dump_lat: number;
	dump_lng: number;

	has_tickets?: boolean;

	/**
	 * Timezone name 
	 */
	zone_name: string = "America/New_York";

	shift_day: Date;

	get shiftDay(){
		return this.shift_day
	}

	start_datetime: Date;
	end_datetime: Date;

	// FINAL
	expire_at: Date
	shift_datetime: Date

	updateEndTime(){
		if(this.start_datetime && this.end_datetime){
			const hours = this.end_datetime.getHours()
			const minutes = this.end_datetime.getMinutes()
			const tempDate = new Date(this.start_datetime)
			if((this.start_datetime.getHours() * 60 +  this.start_datetime.getMinutes()) > (this.end_datetime.getHours() * 60 +  this.end_datetime.getMinutes())){
				tempDate.setDate(tempDate.getDate()+1)
			}
			tempDate.setHours(hours)
			tempDate.setMinutes(minutes)
			this.end_datetime = tempDate
		}
	}

	set startDateTime(value: Date){
		this.start_datetime = value
		this.updateEndTime()
	}

	get startDateTime(){
		return this.start_datetime
	}

	set endDateTime(value: Date){
		this.end_datetime = value
		this.updateEndTime()
		const hours = this.end_datetime.getHours()
		const minutes = this.end_datetime.getMinutes()

		if(this.expire_at && this.end_datetime > this.expire_at){
			const tempDate = new Date(this.expire_at)
			tempDate.setHours(hours)
			tempDate.setMinutes(minutes)
			this.expire_at = tempDate
		}
	}

	get endDateTime(){
		return this.end_datetime
	}

	set shiftDay(value: Date){
		this.shift_day = value
		const month = this.shift_day.getMonth()
		const year = this.shift_day.getFullYear()
		const day = this.shift_day.getDate()

		new Array('start_datetime', 'end_datetime').forEach((key) => {
			if(this[key]){
				this[key].setDate(day)
				this[key].setMonth(month)
				this[key].setFullYear(year)
			} else {
				const tempDate = new Date(this.shift_day)
				if(key === 'start_datetime'){
					tempDate.setHours(6)
				} else {
					tempDate.setHours(16)
				}
				this[key] = tempDate
			}
		})
		this.updateEndTime()
		if(this.expire_at && this.shift_day > this.expire_at){
			this.expire_at.setDate(day)
			this.expire_at.setMonth(month)
			this.expire_at.setFullYear(year)
		} else if(!this.expire_at && this.isExpireActive){
			this.expire_at = this.end_datetime
		}
	}

	set isExpireActive(value: boolean) {
		if (value) {
			if(!this.expire_at && this.end_datetime){
				this.expire_at = this.end_datetime
			}
		} else {
			this.expire_at = null
		}
		this.is_expire_active = value
	}

	get isExpireActive() {
		return this.is_expire_active
	}

	set isBrokerStatusActive(value: boolean) {
		this.is_broker_status_at = value
		if (!value) {
			this.broker_status_at = null
		}
	}

	get isBrokerStatusActive() {
		return this.is_broker_status_at
	}

	payment_type: PaymentTypeEnum = PaymentTypeEnum.CYCLE;

	cycles: number;
	fee: number;
	distance: number;

	trucks_min: number = 0;
	trucks_required: number;
	trucks_engaged: number;

	pause_minutes: number;
	note_public: string;
	note_private: string;
	state_id: number;
	contact_person: string;
	contact_phone: string;
	route: Path;
	w3load: string;
	w3dump: string;

	load_minutes: number = 3;
	load_schedule: boolean = true;

	poc_name: string;
	poc_phone: string;
	poc_email: string;

	status: ShiftStatusEnum;

	estimated_ton: number;

	customer_id?: number;
	customer?: Company;

	is_ton_requested: boolean = false;

	updateDates(){
		['shift_datetime', 'start_datetime', 'end_datetime', 'expire_at', 'broker_status_at'].forEach((key) => {
			if(this[key]){
				this[key] = new Date(moment(this[key]).tz(this.zone_name).format('YYYY-MM-DDTHH:mm:ss'))
			}
		})
	}

}

export enum ShiftStatusEnum {
	ENABLED = 1,
	DISABLED = 2,
	TO_BE_APPROVED = 3
}
