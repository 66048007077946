import { MessageModel } from "@/model/api/Message";
import { CRUD } from './base/crud';

class MessageService extends CRUD<MessageModel, any> {
    readonly endPoint = `notifications`;

    public send(message: MessageModel){
        return this.post(`${this.endPoint}/send`, message);
    }

}

export const messageService = new MessageService();



