import { Ticket } from "@/model/api/Ticket";
import { BaseApiFilter } from "@/model/filters/BaseApiFilter";
import { ticketsService } from "@services/tickets.service";
import { defineComponent, ref, toRefs, watch } from "vue";

export declare class ITicketAutocomplete{
    $props: {
        modelValue?: string | Ticket;
    };

    $emit(eventName: 'selected', value: Ticket): any;
    $emit(eventName: 'update:modelValue', value: Ticket): any;
}

export default defineComponent({
    name: 'TicketAutocomplete',

    inheritAttrs: false,

    emits: ['selected', 'update:modelValue'],

    props: ['modelValue'],

    setup(_, { emit }) {
        const { modelValue } = toRefs(_);
        
        const selected    = ref(null);
        const suggestions = ref(null);

        async function search({query}) {
            const f = new BaseApiFilter();
            f.q = query;

            const response = await ticketsService.index(f);

            suggestions.value = response.data;
        }

        function onSelect(item: Ticket) {
            emit('update:modelValue', item);
            emit('selected', item);
        }

        watch(
          modelValue,
          () => {
            emit('update:modelValue', modelValue.value);
          },
          {
            immediate: true
          }
        );

        
        function selectedLabel(item: Ticket) {
            return `T. #${item.id} W. #${item.shift_id} - ${item.user.name}${item.user.surname}`;
        }

        return {
            selected,
            suggestions,
            search,
            onSelect,
            selectedLabel
        }
    }
});