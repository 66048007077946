import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d2f1e150")
const _hoisted_1 = {
  key: 0,
  class: "ticket status_"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_ctx.status)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_Chip, _mergeProps({
          label: _ctx.statusText,
          class: "p-text-nowrap custom-chip"
        }, _ctx.$attrs), null, 16, ["label"])
      ]))
    : _createCommentVNode("", true)
}