import { User } from '@/model/api/User';
import { CRUD } from './base/crud';
import {PaginatedResponse} from "@/model/api/PaginatedResponse";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {RolesUserCanSee} from "@/model/enums/UserRoleEnum";
import {authStore} from "@/modules/auth/store";

class UsersService extends CRUD<User, any> {
    readonly endPoint = `users`;

    public index(args?: any){

        const params      = this.getCleanParams(args);
        const cancelToken = this.generateCancelToken();

        const currentRoute = router.currentRoute.value; // Get the current route
        if(!params.filters || !params.filters.role){
            if(currentRoute.name === 'admins-list'){
                params.filters = {
                    ...params.filters,
                    role: {
                        matchMode: FilterMatchMode.IN,
                        value: RolesUserCanSee[authStore.getters.me.role]
                    }
                }
            } else if(currentRoute.name === 'users-list'){
                params.filters = {
                    ...params.filters,
                    role: {
                        matchMode: FilterMatchMode.IN,
                        value: RolesUserCanSee[authStore.getters.me.role]
                    }
                }
            }
        }

        return this.get<PaginatedResponse<any>>(
            `${this.endPoint}`,
            { params, cancelToken }
        );
    }

    public exportFile(filters?: any): Promise<string>{
        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k].value;
                const n = filters[k].nullable;

                if (!v && !n) {
                    delete filters[k];
                }
            });

            if (!Object.keys(filters)?.length) {
                filters = null;
            }
        }

        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'arraybuffer',
            params: { filters, filename: 'drivers' }
        })
    }

    public getBasicById(id: number): Promise<User>{
        return this.get<User>(`${this.endPoint}/basic/${id}`);
    }

    sendVerificationEmail(userId){
        return this.post<any>(`${this.endPoint}/${userId}/send-validation-email`);
    }

    uploadFiles(userId: number, files: UploadFiles) {

        const {
            insuranceFile, 
            licenseFile,
            avatarImage,
            truckImage,
        } = files;

        const formData = new FormData();
        
        if (licenseFile) {
            formData.append("license_image", licenseFile);
        }

        if (insuranceFile) {
            formData.append("insurance_image", insuranceFile);
        }

        if (avatarImage) {
            formData.append("avatar_image", avatarImage);
        }

        if (truckImage) {
            formData.append("truck_image", truckImage);
        }

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`${this.endPoint}/${userId}/upload-files`, formData, options);
    }

    downloadFile(url: string) {
        return this.get(url, { responseType: 'arraybuffer' });
    }
}

interface UploadFiles {
    insuranceFile: File,
    licenseFile: File,
    avatarImage: File,
    truckImage: File
}

export const usersService = new UsersService();



