import * as api from "@what3words/api";

import { toRaw } from "@vue/reactivity";
import { Options, Vue } from "vue-class-component";

import {
    W3GetCoordinates
} from "@components";
import { Prop } from "vue-property-decorator";

type w3r = api.LocationJsonResponse;

@Options({
    components: {
        W3GetCoordinates
    }
})
export default class ZonePage extends Vue {

    @Prop()
    readonly initialValue: string;

    mapRef: google.maps.Map = null;
    marker: google.maps.Marker = null;

    w3Result: string | w3r = null;

    get map() {
        return toRaw(this.mapRef);
    }

    get w3El() {
        return this.$refs.w3El as typeof W3GetCoordinates;
    }

    get sidebar() {
        const refs: any = this.$refs;
        return refs.sidebar;
    }

    onW3Result(result: w3r) {
        this.w3Result = result;

        this._updateMarker();
        this._centerOn(result.coordinates)
    }

    onSave() {
        this.$emit('save', this.w3Result);
        this.sidebar.hide();
    }

    onCancel() {    
        this.sidebar.hide();
    }

    private _initMarker() {
        this.marker = new google.maps.Marker({
            draggable: true
        });

        this.marker.addListener('click', () => {
            this.map.setZoom(20);
            this.map.setCenter(this.marker.getPosition());
        });

        this.marker.addListener('dragend', () => {
            const latLng = this.marker.getPosition();

            const lat = latLng.lat();
            const lng = latLng.lng();

            api.convertTo3wa({ lat, lng }).then(r => {
                this.w3Result = r;
            })
        });
    }

    private _centerOn(coordinates) {
        this.map.setZoom(16);
        this.map.setCenter(coordinates);
    }

    private _updateMarker() {
        this.marker.setPosition(
            (this.w3Result as w3r).coordinates
        );

        if (!this.marker.getMap()) {
            this.marker.setMap(this.map);
        }
    }

    private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {

        this.mapRef = new google.maps.Map(
            element,
            {
                center: this.$config.startCenter,
                zoom: this.$config.startZoom,
            },

        );

        if (bounds) {
            this.map.fitBounds(bounds, 0);
        }
    }

    onShow() {
        this._initMarker();
        this._buildMap(document.getElementById('w3_map'));

        if (this.initialValue) {
            api.convertToCoordinates(this.initialValue)
                .then(r => {
                    this.onW3Result(r);
                })
        }
    }

    onHide() {
        this.w3Result = null; 
        this.mapRef = null; 
    }
}