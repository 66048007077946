import { State } from "@/model/api/State";
import { domainService } from "@services/domain.service";
import { computed, defineComponent, ref } from "vue";
import { toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";

export declare class IStateAutocomplete{
    $props: {
        modelValue?: string | State;
    };

    $emit(eventName: 'selected', value: State): any;
    $emit(eventName: 'update:modelValue', value: State): any;
}

export default defineComponent({
    inheritAttrs: false,

    emits: ['selected', 'update:modelValue'],

    props: [
        'modelValue',
        'label'
    ],

    setup(_, { emit }) {
        const { modelValue, label } = toRefs(_);
        
        const selected    = ref(null);
        const suggestions = ref(null);

        async function search({query}) {
            const response = await domainService.states(query);
            suggestions.value = response.data;
        }

        function onSelect(item: State) {
            emit('update:modelValue', item);
            emit('selected', item);
        }

        watch(
          modelValue,
          () => {
            emit('update:modelValue', modelValue.value);
          },
          {
            immediate: true
          }
        );

        const {t}= useI18n();
        const theLabel = computed( () => {
            return label.value ? label.value : t('state_autocomplete.label')
        })

        return {
            selected,
            suggestions,
            search,
            onSelect,
            theLabel
        }
    }
});