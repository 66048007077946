import { TimeZone }        from "@/model/api/TimeZone";
import { timezoneService } from "@services/timezone.service";
import { Options, Vue }    from "vue-class-component";
import { Model }           from "vue-property-decorator";

@Options({})
export default class TimeZoneDropdown extends Vue {
  @Model('modelValue')
  model: string; 

  options: TimeZone[] = null; 

  isLoading: boolean = true;

  private async _loadOptions() {
    try {
      const response = await timezoneService.timezones();
      this.options = response;
    } finally {
      this.isLoading = false;
    }
  }

  created() {
    this._loadOptions();
  }
}