import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = {
  class: "p-field p-fluid",
  style: {"flex":"1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    header: "Edit Ticket"
  }, _ctx.$attrs, { onShow: _ctx.onShow }), {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        class: "p-button-info",
        icon: "pi pi-times",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:visible', false)))
      }),
      _createVNode(_component_Button, {
        label: "Edit",
        disabled: _ctx.requestPending,
        onClick: _ctx.onEdit
      }, null, 8, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FloatLabel, {
          label: _ctx.$t('ticket.broker_deduct'),
          style: {"min-width":"0","align-self":"flex-end"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.broker_deduct,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.broker_deduct = $event)),
              mode: "decimal",
              minFractionDigits: 1,
              min: 0,
              max: 100
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FloatLabel, {
          label: _ctx.$t('ticket.broker_deduct_note')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              autoresize: true,
              modelValue: _ctx.broker_deduct_note,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.broker_deduct_note = $event)),
              rows: 3
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"])
      ])
    ]),
    _: 1
  }, 16, ["onShow"]))
}