export enum TicketStatusEnum {
    DECLINED            = 10,
    ACCEPTED_BY_USER    = 20,
    DELETED_BY_USER     = 30,
    DELETED_BY_SYSTEM   = 40,
    WAITING_LOAD        = 100,
    LOADED              = 110,
    WAITING_DUMP        = 120,
    DUMPED              = 130,
    DISPUTE             = 200,
    CLOSED              = 300,
    FREE_DUMP_REQUESTED = 115,
    FREE_DUMP_ACCEPTED  = 116
}


export enum TicketFinishStatusEnum {
    COMPLETED        = 10,
    TRUCK_ISSUE      = 20,
    SITE_ISSUE       = 30,
    CLIENT_CANCELLED = 40,
    HEALTH_ISSUE     = 50,
    SITE_ACCESS      = 60,
    OTHER            = 100,
    SYSTEM           = 200, 
}

export const finishStatuses = [
    TicketFinishStatusEnum.COMPLETED,
    TicketFinishStatusEnum.TRUCK_ISSUE,
    TicketFinishStatusEnum.SITE_ISSUE,
    TicketFinishStatusEnum.CLIENT_CANCELLED,
    TicketFinishStatusEnum.HEALTH_ISSUE,
    TicketFinishStatusEnum.SITE_ACCESS,
    TicketFinishStatusEnum.OTHER,
    TicketFinishStatusEnum.SYSTEM,
];

export const activeStatuses = [
    TicketStatusEnum.ACCEPTED_BY_USER,
    TicketStatusEnum.WAITING_LOAD,
    TicketStatusEnum.LOADED,
    TicketStatusEnum.WAITING_DUMP,
    TicketStatusEnum.DUMPED,
    TicketStatusEnum.DISPUTE,
    TicketStatusEnum.CLOSED,
    TicketStatusEnum.DELETED_BY_USER,
    TicketStatusEnum.DELETED_BY_SYSTEM
];

export const inactiveStatuses = [
    // TicketStatusEnum.DECLINED,
    TicketStatusEnum.DELETED_BY_SYSTEM,
    TicketStatusEnum.DELETED_BY_USER,
];
