import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a9576442")
const _hoisted_1 = { class: "p-mr-1" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_Button = _resolveComponent("Button")!
  const _component_AppTable = _resolveComponent("AppTable")!
  const _component_DuplicateShiftDialog = _resolveComponent("DuplicateShiftDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppTable, {
      ref: "dataTable",
      "striped-rows": false,
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetail($event.data))),
      onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDetail($event))),
      sortField: "start_datetime",
      sortOrder: -1,
      showHeader: false,
      rowClass: _ctx.getRowClass
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_Column, { header: "Shift ID" }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.id), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "code",
          header: _ctx.$t('shift.table.name')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "start_datetime",
          header: _ctx.$t('shift.table.start_datetime'),
          sortable: true,
          style: {"min-width":"13rem"}
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_DateFormatted, {
              date: data.start_datetime,
              format: "YYYY-MM-DD hh:mm A"
            }, null, 8, ["date"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "end_datetime",
          header: _ctx.$t('shift.table.end_datetime'),
          sortable: true,
          style: {"min-width":"13rem"}
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_DateFormatted, {
              date: data.end_datetime,
              format: "YYYY-MM-DD hh:mm A"
            }, null, 8, ["date"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "w3load",
          header: _ctx.$t('shift.table.load_zone')
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(data.zone_load.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("i", null, _toDisplayString(data.w3load), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "w3dump",
          header: _ctx.$t('shift.table.dump_zone')
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(data.zone_dump.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("i", null, _toDisplayString(data.w3dump), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: "minimum #",
          field: "trucks_min",
          style: {"min-width":"9rem"}
        }),
        _createVNode(_component_Column, {
          header: "Maximum #",
          field: "trucks_required",
          style: {"min-width":"9rem"}
        }),
        _createVNode(_component_Column, {
          header: "# of accepted",
          field: "trucks_engaged",
          style: {"min-width":"9rem"}
        })
      ]),
      actions: _withCtx(({data}) => [
        _createElementVNode("span", _hoisted_1, [
          (data.status !== 2 && _ctx.canDuplicate)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "p-button-info",
                icon: "pi pi-clone",
                onClick: _withModifiers(($event: any) => (_ctx.onClone(data)), ["stop"])
              }, null, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('duplicate'),
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["filtersSchema", "service", "rowClass"]),
    _createVNode(_component_DuplicateShiftDialog, {
      shift: _ctx.selectedShift,
      visible: _ctx.showDuplicateDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDuplicateDialog = $event)),
      onConfirm: _ctx.onDuplicateConfirm,
      onCancel: _ctx.onDuplicateCancel
    }, null, 8, ["shift", "visible", "onConfirm", "onCancel"])
  ], 64))
}