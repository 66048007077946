import { TimeZone } from '@/model/api/TimeZone';
import { HttpService } from './base/http.service';

class TimezoneService extends HttpService {
    readonly endPoint = `timezone`;

    public timezones() {
        return this.get<TimeZone[]>(`${this.endPoint}`);
    }
}

export const timezoneService = new TimezoneService();



