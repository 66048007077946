
import {defineComponent, onMounted, ref, toRefs, watch} from "vue";
import {usersService} from "@services/users.service";
import {User} from "@/model/api/User";
import {Contract} from "@/model/api/Contract";

export default defineComponent({
  name: "UserFlattenAutocomplete",

  inheritAttrs: false,
  emits: ['select'],
  props: [
    "modelValue",
    "params",
    "queryOpts",
    "showStatus",
    "label",
    "noDropdown"
  ],

  setup(_, {emit}) {
    const {modelValue, params, queryOpts} = toRefs(_);

    // const options = ref<User[]>([]);

    const searchBox = ref<string | Contract>("");
    const suggestions = ref(null);
    const selected = ref(null);

    async function search({query}) {

      const text = (query as string).toLowerCase().trim();

      const response = await usersService.index({
        ...queryOpts.value,
        q: text,
        filters: {
          ...params.value
        }
      });
      suggestions.value = response.data
    }

    async function onSelect({value}) {
      selected.value = value;
      emit("select", value);
    }

    async function onClear() {
      searchBox.value = null
      emit("select", null);
    }

    async function _initOptions() {
      // const response = await usersService.index({
      //   filters: {
      //     ...queryOpts.value,
      //     ...params
      //   }
      // });
      // options.value = response.data
    }

    watch(
        modelValue,
        () => {
          searchBox.value = modelValue.value;
        },
        {
          immediate: true,
        }
    );

    onMounted(() => _initOptions());


    function selectedLabel(item: User) {
      return `${item.name} ${item.surname}`;
    }

    return {
      searchBox,
      suggestions,
      onClear,
      onSelect,
      search,
      selectedLabel
    };
  },
});
