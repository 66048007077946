
  import { configuration }        from "@plugins/Config-plugin";
  import { defineComponent, ref } from "vue";
  import * as w3api               from "@what3words/api";

  export default defineComponent({
    inheritAttrs: false,

    emits: ["selected", "update:modelValue"],

    props: ["modelValue"],

    setup(_, { emit }) {
      w3api?.setOptions({ key: configuration.w3wKey });

      const isGoogleResults = ref(false);
      const selected        = ref(null);
      const suggestions     = ref(null);

      const gPlaceAuto = new google.maps.places.AutocompleteService();
      const geocoder   = new google.maps.Geocoder();

      async function search({ query }: { query: string }) {
        const myRegex = /^(\/{3})?([a-zA-Z]+\.{1}){2,}[a-zA-Z]+$/;
        
        if (myRegex.test(query)) {
          /*
            La stringa immessa sembra una W3W 
            quindi procedo con il relativo servizio
          */
          w3api.autosuggest(query).then((data) => {
            isGoogleResults.value = false;
            
            suggestions.value = data.suggestions;
          });
        } else {
          /*
            La stringa immessa non rispetta lo standard W3W
            procedo con le ricerca delle coordinate con Google
          */
          gPlaceAuto.getPlacePredictions({
            input: query,
          }).then( response => {
            isGoogleResults.value = true;

            suggestions.value = response.predictions.map( p => ({
              words    : p.description,
              place_id : p.place_id
            }))
          });
        }
      }

      function onSelect(item) {
        if (isGoogleResults.value) {
          geocoder.geocode(
            { placeId: item.place_id }, 
            (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                  const lat = results[0].geometry.location.lat();
                  const lng = results[0].geometry.location.lng();
                  
                  w3api.convertTo3wa({ lat, lng })
                    .then(w3r => {
                      emit("update:modelValue", w3r.words);
                      emit("selected", w3r.words);
                    })
                }
            }
          });
        } else {
          emit("update:modelValue", item.words);
          emit("selected", item.words);
        }
      }

      return {
        selected,
        suggestions,
        search,
        onSelect,
      };
    },
  });
