import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-d-flex p-ai-center"
}
const _hoisted_2 = { class: "p-ml-2" }
const _hoisted_3 = { class: "p-d-flex p-ai-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("header", null, [
    (!_ctx.noBack)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BackButton, { to: _ctx.backRoute }, null, 8, ["to"])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _renderSlot(_ctx.$slots, "actions", {}, () => [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "leftActions"),
        _withDirectives(_createVNode(_component_Button, {
          disabled: _ctx.saveDisabled,
          icon: "pi pi-save",
          class: "p-mr-1",
          onClick: _ctx.onSave
        }, null, 8, ["disabled", "onClick"]), [
          [
            _directive_tooltip,
            _ctx.$t('save'),
            void 0,
            { bottom: true }
          ]
        ]),
        (_ctx.showDelete)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 0,
              disabled: _ctx.deleteDisabled,
              icon: "pi pi-trash",
              class: "p-button-danger",
              onClick: _ctx.onDelete
            }, null, 8, ["disabled", "onClick"])), [
              [
                _directive_tooltip,
                _ctx.$t('delete'),
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}