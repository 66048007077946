import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-60c19d2f")
const _hoisted_1 = ["for"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  style: {"display":"grid","place-items":"center","width":"100%"}
}
const _hoisted_4 = {
  key: 0,
  class: "p-d-flex p-ai-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["image-input-container", _ctx.$attrs.class])
    }, [
      _createElementVNode("label", {
        for: _ctx.id,
        class: _normalizeClass({'image-input-placeholder' : !_ctx.imageUrl}),
        style: {"min-width":"100px"}
      }, [
        (_ctx.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageUrl,
              style: {}
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_FaIcon, {
                icon: "upload",
                size: "6x"
              })
            ])),
        _createElementVNode("input", _mergeProps({
          hidden: "",
          ref: "imgInput"
        }, _ctx.$attrs, {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
          type: "file",
          multiple: false,
          accept: "image/jpg, image/jpeg, image/png"
        }), null, 16)
      ], 10, _hoisted_1)
    ], 2),
    (_ctx.file?.name)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.file?.name) + " ", 1),
          _createVNode(_component_Button, {
            icon: "pi pi-times",
            class: "p-button-text p-button-danger",
            onClick: _ctx.removeFile
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}