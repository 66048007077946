
import { defineComponent, onMounted, toRefs, watch } from "vue";
import { useAutocompleteWithParams } from "@/use/useAutocomplete";
import { Company } from "@/model/api/Company";
import { companiesService } from "@services/companies.service";
import { FilterMatchMode } from "primevue/api";

export default defineComponent({
  name: "CompanyAutocomplete",

  inheritAttrs: false,

  emits: ['select'],

  props: ["modelValue", "type", "label", "showDropdown"],

  setup(_, { emit }) {

    onMounted(() => {
      document.querySelectorAll('input.p-autocomplete-input[autocomplete=off]').forEach(e => {        
        e.setAttribute('autocomplete', 'none')
      })
    })

    const { modelValue, type } = toRefs(_);

    const { onSelect, search, searchBox, suggestions } =
      useAutocompleteWithParams<Company>({
        service: companiesService,

        params: { type: { value: type.value, matchMode: FilterMatchMode.EQUALS } },

        afterSelect: (item: Company) => {
          emit("select", item);
        },
      });

    function onChange(e: Event) {
      const v = (e.target as HTMLInputElement).value;

      if (!v) {
        emit("select", null);
      }
    }

    function onClear() {
      emit("select", null);
    }

    watch(
      modelValue,
      () => {
        searchBox.value = modelValue.value;
      },
      {
        immediate: true,
      }
    );

    return {
      searchBox,
      suggestions,

      onChange,
      onSelect,
      onClear,
      search
    };
  },
});
