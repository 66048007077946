import { Options, Vue }     from "vue-class-component";
import {Prop, Provide} from "vue-property-decorator";

import moment               from "moment";
import timezone             from "moment-timezone";

import { ticketsService }   from "@services/tickets.service";
import { TicketStatusEnum } from "@/model/enums/TicketStatusEnum";
import {Ticket, TicketHistory} from "@/model/api/Ticket";

import TableSkeleton        from "@/components/TableSkeleton/TableSkeleton.vue";
import TicketStatus         from "@/components/TicketStatus/TicketStatus.vue";
import {TonImageDialog} from "@components";
import {User} from "@/model/api/User";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {PaymentStatusEnum} from "@/model/enums/PaymentStatusEnum";
import {companiesService} from "@services/companies.service";

@Options({
  name: 'TicketHistoryComponent',
  components: {
    TableSkeleton,
    TicketStatus,
    TonImageDialog
  }
})
export default class TicketHistoryComponent extends Vue {
  @Prop() readonly ticket!: Ticket;

  @Prop() readonly timeZone!: string;

  @Prop() readonly hiddenColumns!: any;

  displayTonDialog  : boolean = false;
  tonDialogSrc      : string   = '';

  history: TicketHistory[] = null;

  get TicketStatusEnum() {
    return TicketStatusEnum;
  }

  canUploadImage(status: TicketStatusEnum){
    return (status === TicketStatusEnum.LOADED || status === TicketStatusEnum.DUMPED) &&
        (this.me === UserRoleEnum.SUPER_ADMIN || this.me === UserRoleEnum.CUSTOMER)
  }

  isTicketHistoryTonEditable(ticketHistory: TicketHistory){
    return ticketHistory.status === TicketStatusEnum.LOADED &&
        (this.me === UserRoleEnum.SUPER_ADMIN || this.me === UserRoleEnum.CUSTOMER) &&
        (!this.ticket.payment_status || this.ticket.payment_status === PaymentStatusEnum.PENDING)
  }

  private async editTicketHistoryTon(ticketHistory: TicketHistory) {
    this.$waitFor(
        async () => {
          await ticketsService.editTicketHistoryTon(ticketHistory.id, ticketHistory.ton);
          (ticketHistory as any).original_ton = ticketHistory.ton
          this.$successMessage("history successfully updated")
        },
        this.$t('Operation failed'),
    );
  }

  timeZoneDate(date: string) {
    return timezone
      .tz(date, this.timeZone)
      .format("hh:mm A")
  }

  get me(){
    return authStore.getters.me.role
  }


  async onFileChange(event: Event, ticketHistory: any) {
    const file = (event.target as HTMLInputElement).files[0];
    const res = await ticketsService.uploadTicketHistoryImage(ticketHistory.id, {image: file})
    ticketHistory.image_path_url = res.image_path_url
    this.$successMessage("history successfully updated")
  }

  editTicketTonVisible(ticketHistory: TicketHistory){
    return ticketHistory.ton !== ticketHistory.original_ton &&
        this.isTicketHistoryTonEditable
  }

  private _loadhistory() {
    this.$waitFor(async () => {
      this.history = await ticketsService.history(this.ticket.id);
      this.history.forEach((wl: any) => {
        wl.original_ton = wl.ton;
      })
    })
  }

  showTonImage(ton_image_path_url: string){
    this.tonDialogSrc = ton_image_path_url
    this.displayTonDialog = true
  }

  getDurationFrom(idx: number, th: TicketHistory){
    if ( this.history.length < idx+2) return; 

    const startMoment = moment(th.created_at)
    const endMoment   = moment(this.history[idx+1].created_at);

    const d = moment.duration(endMoment.diff(startMoment));

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());

    return [h,m,s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  timeDiffNext(idx: number, th: TicketHistory){
    const format = "hh:mm A";

    let result = timezone.tz(th.created_at, this.timeZone).format(format);

    if (this.history.length > idx+1){
      const endMoment   = timezone.tz(
        this.history[idx+1].created_at, 
        this.timeZone
      )?.format(format);

      result += ` - ${endMoment}`;
    }

    return result;
  }
  

  created() {
    if (this.ticket) {
      this._loadhistory();
    }
  }
}
