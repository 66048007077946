import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createBlock(_component_FloatLabel, {
    label: _ctx.$t('timezone.label')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dropdown, _mergeProps({
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.model = $event)),
        options: _ctx.options,
        optionLabel: "zoneName",
        optionValue: "zoneName",
        placeholder: _ctx.$t('timezone.placeholder'),
        loading: _ctx.isLoading,
        filter: true
      }, _ctx.$attrs), null, 16, ["modelValue", "options", "placeholder", "loading"])
    ]),
    _: 1
  }, 8, ["label"]))
}