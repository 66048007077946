import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PaymentStatusEnum} from "@/model/enums/PaymentStatusEnum";

@Options({
  name: 'PaymentStatus',
  inheritAttrs: false
})
export default class PaymentStatus extends Vue {
  @Prop() readonly status!: PaymentStatusEnum;

  ticketStatusEnum(){
    return TicketStatusEnum
  }

  get statusText() {
    return this.status ? this.$t(`ticket.payment_statuses.${PaymentStatusEnum[this.status]}`) : '--';
  }
}