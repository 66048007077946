import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, {
    modelValue: _ctx.calendarDate,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.calendarDate = $event)),
    showTime: true,
    showSeconds: false,
    showIcon: true,
    maxDate: _ctx.maxMomentToDate,
    minDate: _ctx.minMomentToDate,
    hourFormat: "12",
    dateFormat: "yy-mm-dd"
  }, null, 8, ["modelValue", "maxDate", "minDate"]))
}