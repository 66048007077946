import {Contract} from '@/model/api/Contract';
import {CRUD} from './base/crud';

class ContractsService extends CRUD<Contract, any> {
    readonly endPoint = `contracts`;

    getByCustomerId(id, q?) {
        return this.get<any>(
            `${this.endPoint}`,
            {
                params: {
                    customer_id: id,
                    q
                }
            }
        );
    }
}

export const contractsService = new ContractsService();



