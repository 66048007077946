import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import UserAutocomplete from "@/components/UserAutocomplete/UserAutocomplete.vue";
import {User} from "@/model/api/User";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    UserAutocomplete
  }
})
export default class AddTicketDialog extends Vue {
  @Prop()
  shiftId: number;
  @Prop()
  brokers: any[];

  user: User = null
  truck_number: string = null

  get userFilters(){
    return {
      "role":{
        "value": [
          UserRoleEnum.DRIVER,
          UserRoleEnum.DRIVER_OWNER_OPERATOR
        ],
        "matchMode":"in"
      },
      "broker_id":{
        "value":this.brokers.map((broker) => broker.id),
        "matchMode":"in"
      }
    }
  }

  private save(){
    if(this.user){
      this.$emit('selected', {
        driver_id: this.user.id,
        truck_number: this.truck_number
      });
    } else {
      this.$errorMessage(this.$t(`User field is required`))
    }
  }

  private close() {
    this.$emit('update:visible', false);
  }
}
