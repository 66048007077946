import { BaseApiModel } from "../common/BaseApiModel";
import { ZoneTypeEnum } from "../enums/ZoneTypeEnum";
import { Company } from "./Company";
import { State } from "./State";
import { ZoneFile } from "./ZoneFile";

export class Zone extends BaseApiModel {
    name           : string;
    files          : ZoneFile[];
    zone_type      : ZoneTypeEnum;
    state          : State;
    state_id       : number;
    shape          : Path;
    lat            : number;
    lng            : number;
    w3             : string;
    info_zone      : string;
    note_public    : string;
    note_private   : string;
    contact_person : string;
    contact_phone  : string;
    client_id      : number;
    client         : Company;

    /**
     * Close the shape if it isn't closed.
     * To be closed, first and last point have to be the same
     */
    closeShape() {
        // First point
        const [firstLng, firstLat] = this.shape.first();
        // Last point
        const [lastLng, lastLat]   = this.shape.last();
    
        if (lastLat !== firstLat || lastLng !== firstLng) {
          this.shape.push(this.shape[0]);
        }
    }
}

type Lat = number;
type Lng = number;

type Point = [Lng, Lat];

export type Path = Point[];