import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-mb-0" }
const _hoisted_2 = { class: "p-formgroup" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { for: "shift_code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    style: {width: '450px'},
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    onShow: _ctx.onShow,
    minX: 0,
    minY: 0
  }, _ctx.$attrs), {
    header: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('duplicate_shift_dialog.title')), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('cancel'),
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel')))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        form: "shift_form",
        label: _ctx.$t('confirm'),
        icon: "pi pi-check",
        class: "p-button-text",
        type: "submit"
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        id: "shift_form",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('duplicate_shift_dialog.new_code')), 1),
            _createVNode(_component_InputText, {
              id: "shift_code",
              placeholder: _ctx.$t('duplicate_shift_dialog.new_code_placeholder'),
              modelValue: _ctx.newCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newCode = $event)),
              required: ""
            }, null, 8, ["placeholder", "modelValue"])
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 16, ["onShow"]))
}