import { Options, Vue } from "vue-class-component";

import {
  Field as VField
} from 'vee-validate';

import { Prop } from "vue-property-decorator";

import {
  VI18nError
} from "@components";

@Options({
  inheritAttrs: false, 
  components: {
    VField,
    VI18nError
  }
})
export default class VDropdown extends Vue {
  @Prop()
  readonly id!: string;

  @Prop()
  readonly name!: string;

  @Prop()
  readonly label!: string;

  @Prop()
  readonly options!: {label: string, value: any}[];

  onUpdate(value, field, attrs){
    field['onUpdate:modelValue'][0](value);
    attrs['onUpdate:modelValue'](value);
  }


  get validationSchema(){
    return (this.$parent as any).validationSchema;
  }

  get rules(){
    return this.validationSchema?.[this.name] as string;
  }

  get isRequired(){
    return this.rules?.includes('required'); 
  }
  
  onChange(value, handleChange){
    this.$emit('update:modelValue', value);
    handleChange(value);
  }
}