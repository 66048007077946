import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class TonImageDialog extends Vue {
  @Prop()
  src: string;
  public rotation: number = 0;
  public dialogWidth: number | string = 400;
  public dialogHeight: number | string = 300;

  rotateImg() {
    if(this.rotation !== 360){
      this.rotation += 90;
    } else {
      this.rotation = 0
    }
    this.adjustDialogContentSize();
  }

  get dialogStyle(){
    return { width: this.dialogWidth + 'px', height: this.dialogHeight + 'px' }
  }

  adjustDialogContentSize() {
    const image: any = this.$refs.image;

    if(image.naturalWidth < window.innerWidth || image.naturalHeight < window.innerHeight){
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      if (this.rotation === 90 || this.rotation === 270) {
        this.dialogWidth = imageHeight + 30;
        this.dialogHeight = imageWidth + 104;
      } else {
        this.dialogWidth = imageWidth + 30;
        this.dialogHeight = imageHeight + 104;
      }
    } else if(image.naturalHeight > window.innerHeight){

      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      const aspectRatio = imageWidth / imageHeight;

      this.dialogWidth = window.innerHeight * aspectRatio;
      this.dialogHeight = window.innerHeight;
    }
  }
}
