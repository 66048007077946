import { Options, Vue } from "vue-class-component";

import MessageEditor from "@/components/MessageEditor/MessageEditor.vue";
import { MessageModel } from "@/model/api/Message";
import {Prop, Provide} from "vue-property-decorator";
import { messageService } from "@services/message.service";
import moment from "moment";
import {Shift} from "@/model/api/Shift";

@Options({
  components: {
    MessageEditor
  }
})
export default class SendMessageDialog extends Vue {
  @Prop()
  userId: number;

  @Prop()
  readonly shift: Shift;

  @Prop()
  readonly startDateTime: Date

  @Prop({default: null})
  hint: string;

  @Prop()
  text: string;

  @Prop()
  subject: string;

  @Prop({ default: false })
  onlyDriversWorkingVisible: boolean;

  @Prop({ default: false })
  isSendToBrokersVisible: boolean;

  @Prop({ default: false })
  all: boolean;

  message: MessageModel = new MessageModel();

  onShow() {
    this.text && (this.message.text = this.text);
    this.subject && (this.message.subject = this.subject);
    if(this.shift != null){
      const localStartDate = moment(new Date(this.shift?.startDateTime)).tz(this.shift.zone_name)
      const localCurrentDate = moment(new Date()).tz(this.shift.zone_name)

      if(localStartDate.diff(localCurrentDate) > 0){
        this.message.is_only_drivers_working = true
      }
    }

  }
  onHide() {
    this.reset();
  }

  async send() {
    this.message.user_id  = this.userId;
    if(this.shift){
      this.message.shift_id = this.shift.id;
    }
    this.$waitFor( async () => {
      await messageService.send(this.message);
      this.$successMessage("Message sent");
      this.$emit("sent");

      this.reset();
      
      this.close();
      
    });
  }

  private reset() {
    this.message = new MessageModel();
  }

  private close() {
    this.$emit('update:visible', false);
  }
}
