import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["ticket", {'p-d-flex' : _ctx.status === _ctx.ticketStatusEnum().DUMPED && _ctx.is_free_dumped}])
  }, [
    _createVNode(_component_Chip, _mergeProps({ class: "p-text-nowrap custom-chip" }, _ctx.$attrs), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.statusText), 1)
      ]),
      _: 1
    }, 16),
    (_ctx.status === _ctx.ticketStatusEnum().DUMPED && _ctx.is_free_dumped)
      ? (_openBlock(), _createBlock(_component_Chip, _mergeProps({
          key: 0,
          class: "p-text-nowrap custom-chip free"
        }, _ctx.$attrs), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('shift.free_dump')), 1)
          ]),
          _: 1
        }, 16))
      : _createCommentVNode("", true)
  ], 2))
}