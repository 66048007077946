import { Options, Vue } from "vue-class-component";

import {Prop} from "vue-property-decorator";
import {invoiceMovementsService} from "@services/invoice_movements.service";
import {InvoiceMovement} from "@/model/api/Invoice";

@Options({})
export default class DeleteInvoiceDialog extends Vue {
  @Prop()
  readonly invoiceId: number;

  password: string

  onShow() {
  }

  onHide() {
  }

  async deleteInvoice() {
    this.$waitFor( async () => {
      await invoiceMovementsService.deleteBySystem(({
        id: `${this.invoiceId}?password=${this.password}`
      } as any));
      this.$emit("deleted");

      this.reset();
      this.close();
    });
  }

  private reset() {
    this.password = null;
  }

  private close() {
    this.$emit('update:visible', false);
  }
}
