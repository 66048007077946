import { Ticket } from "@/model/api/Ticket";
import { TicketStatusEnum } from "@/model/enums/TicketStatusEnum";
import { ticketsService } from "@services/tickets.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import {
  MomentZoneCalendar,
} from "@components";
import moment, { Moment } from "moment";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import { plainToClass } from "class-transformer";

@Options({
  name: "TicketDisputeResolverDialog",
  inheritAttrs: false,
  components: {
    MomentZoneCalendar,
  }
})
export default class TicketDisputeResolverDialog extends Vue {
  @Prop() readonly ticket!: Ticket;
  ttCopy: Ticket = {}

  get datetime_end() {
    return this.ttCopy.datetime_end?.isValid()
      ? this.ttCopy.datetime_end
      : moment();
  }
  set datetime_end(m: Moment){
    this.ttCopy.datetime_end = m;
  }

  get today() {
    return moment();
  }

  set cycles(value: number){
    this.changeInputNumber({value}, 'cycles')
  }

  get cycles(): number{
    return this.ttCopy.cycles
  }

  calcId = null;
  changeInputNumber($event, key){
    if($event.value !== this.ttCopy[key]){
      this.ttCopy[key] = $event.value
      clearTimeout(this.calcId);
      this.calcId = setTimeout(this.calcFee, 300);
    }
  }

  calculatedFee: number = null; 

  get isDispute() {
    return this.ttCopy.status === TicketStatusEnum.DISPUTE;
  }
  
  get hasEndDate() {
    return this.ttCopy?.datetime_end?.isValid();
  }

  get canEdit(){
    return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN || authStore.getters.me.role === UserRoleEnum.CUSTOMER
  }

  get duration() {
    const startMoment = moment(this.ttCopy.datetime_start);
    let d = null;

    if (!this.ttCopy.datetime_end?.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(this.ttCopy.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());

    const htext = this.$t('hours', h).trim();
    const mtext = this.$t('minutes', m).trim();

    return `${h} ${htext}` + (m ? ` ${m} ${mtext}` : '');
  }

  calcInProgress: boolean = false; 
  async calcFee() {
    try {
      this.calcInProgress = true; 
      const response = await ticketsService.calcFee(this.ttCopy);
      this.calculatedFee = response.fee_driver;
    } catch (err){
      if(err)
        this.$errorMessage(err.data?.message);
    }
    finally {
      this.calcInProgress = false; 
      
    }
  }

  onConfirm() {

    if ( !this.ttCopy.datetime_start || !this.ttCopy.datetime_start?.isValid() ) {
      this.$errorMessage("Start time is required");
      return
    }

    this.$waitFor( async () => {

      if ( !this.ttCopy.datetime_end || !this.ttCopy.datetime_end?.isValid()) {
        this.ttCopy.datetime_end = moment();
      }
      
      await ticketsService.setStatus(
        this.ttCopy.id,
        TicketStatusEnum.CLOSED,
        this.ttCopy
      );

      this.$emit('resolved');
    })
  }

  onCancel() {
    this.$emit('cancel');
  }

  onShow() {
    this.ttCopy = plainToClass(Ticket, JSON.parse(JSON.stringify(this.ticket)))
    const validEnd = this.ttCopy.datetime_end?.isValid()
    const validStart = this.ttCopy.datetime_start?.isValid()

    if(!validEnd) {

      const today = moment();
      
      this.ttCopy.datetime_end = !validStart || today.isAfter(this.ttCopy.datetime_start)
        ? today : this.ttCopy.datetime_start;

    }

    if(validStart){
      this.calcFee();
    }
  }
  
}
