import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-932dd954")
const _hoisted_1 = { class: "w3_sidebar_content" }
const _hoisted_2 = {
  id: "w3_map",
  ref: "mapEl",
  class: "p-mt-3"
}
const _hoisted_3 = { class: "p-d-flex p-jc-center p-mt-3" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_W3GetCoordinates = _resolveComponent("W3GetCoordinates")!
  const _component_Fieldset = _resolveComponent("Fieldset")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    ref: "sidebar",
    position: "right",
    class: "p-sidebar-lg custom_sidebar",
    onShow: _ctx.onShow,
    onHide: _ctx.onHide
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Fieldset, { legend: "W3" }, {
          default: _withCtx(() => [
            _createVNode(_component_W3GetCoordinates, {
              ref: "w3El",
              modelValue: _ctx.w3Result,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.w3Result = $event)),
              onResult: _ctx.onW3Result
            }, null, 8, ["modelValue", "onResult"]),
            _createElementVNode("div", _hoisted_2, null, 512)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.$t('save'),
            icon: "pi pi-save",
            class: "p-mr-5",
            onClick: _ctx.onSave
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('cancel'),
            icon: "pi pi-times",
            class: "p-button-info",
            onClick: _ctx.onCancel
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onShow", "onHide"]))
}