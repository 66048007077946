
import { defineComponent } from "vue";
import {RolesUserCanSee, UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {authStore} from "@/modules/auth/store";

export default defineComponent( {
  name: "UserRoleSelect",
  props: ["modelValue", "showAdmin"],
  data() {
    return {
      commonOptions: RolesUserCanSee[authStore.getters.me.role]?.map((val) => {
        return {
          value: val,
          label: this.$t(`role.${val}`)
        }
      }) || [
        {
          value: UserRoleEnum.CLIENT,
          label: 'Client'
        },
        {
          value: UserRoleEnum.BROKER,
          label: 'Broker'
        },
        {
          value: UserRoleEnum.CUSTOMER,
          label: 'Customer'
        },
        {
          value: UserRoleEnum.FOREMAN,
          label: 'Foreman'
        },
        {
          value: UserRoleEnum.PM,
          label: 'Project Manager'
        },
        {
          value: UserRoleEnum.ACCOUNT,
          label: 'Account'
        }
      ]
    }
  },
  computed: {
    selectVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    options() {
      if(this.showAdmin){
        return this.commonOptions.concat({
          value: UserRoleEnum.SUPER_ADMIN,
          label: 'Admin'
        })
      }
      return this.commonOptions
    },
  }

} )
