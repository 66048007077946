import { Shift, ShiftStatusEnum } from "@/model/api/Shift";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment, {Moment} from "moment";
import {plainToClass} from "class-transformer";

@Options({})
export default class DuplicateShiftDialog extends Vue {
  @Prop() shift!: Shift;
  newCode: string = null
  clonedShift: Shift = null;

  get today() {
    return moment().tz(this.shift.zone_name)
  }

  private _momentToDate(v: Moment) {
    if (!v.isValid()) return null;

    return new Date(
        v.format("YYYY-MM-DDTHH:mm:ss.00")
    );
  }

  onShow() {
    if(this.shift){
      this.newCode = this.shift.code
    }

    this.clonedShift = plainToClass(Shift, {
      ...this.shift,
      note_private: null
    });
    // const today = new Date(this.today.format('YYYY-MM-DDTHH:mm:ss'))
    // if(this.clonedShift.start_datetime < today){
    //   this.clonedShift.shiftDay = today
    // }
  }

  // get dateFormat() {
  //   return this.$config.dateFormat;
  // }

  confirm() {
    this.$emit('confirm', {
      ...this.clonedShift,
      id: null,
      code: this.newCode,
      trucks_engaged : 0,
      status: ShiftStatusEnum.ENABLED,
    });
  } 
}
