
  import { defineComponent, toRefs, watch } from "vue";
  import { useAutocompleteWithParams } from "@/use/useAutocomplete";
  import { User } from "@/model/api/User";
  import {usersDriversService} from "@services/users_drivers.service";

  export default defineComponent({
    name: "UserDriverAutocomplete",

    inheritAttrs: false,

    emits: ['select'],

    props: ["modelValue", "showStatus", "broker_id"],

    setup(_, { emit }) {

      const { modelValue, broker_id } = toRefs(_);

      const { onSelect, search, searchBox, suggestions } =
        useAutocompleteWithParams<User>({

          service: usersDriversService,

          params: {},

          getQueryOpts: () => {
            if(broker_id.value){
              return {
                broker_id: broker_id.value
              }
            }
            return null
          },

          afterSelect: (user: User) => {
            emit("select", user);
          },
        });

      function onChange(e: Event) {
        const v = (e.target as HTMLInputElement).value;
        if (!v) {
          emit("select", null);
        }
      }

      function getField(user: User) {
        return `${user.name} ${user.surname}`;
      }

      watch(
        modelValue,
        () => {
          searchBox.value = modelValue.value;
        },
        {
          immediate: true,
        }
      );

      return {
        searchBox,
        suggestions,

        onChange,
        onSelect,
        search,
        getField
      };
    },
  });
