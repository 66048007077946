
import { defineComponent, toRefs, watch } from "vue";
import { useAutocompleteWithParams } from "@/use/useAutocomplete";
import { zonesService } from "@services/zones.service";
import { Zone } from "@/model/api/Zone";
import { FilterMatchMode } from "primevue/api";

export default defineComponent({
  name: 'ZoneAutocomplete',

  inheritAttrs: false,

  emits: ['select', 'clear'],

  props: [
    "modelValue",
    "zoneType",
    "dropdown",
    "customerId"
  ],


  setup(_, { emit }) {
    const { modelValue, zoneType, customerId } = toRefs(_);

    const {
      onSelect,
      onClear,
      search,
      searchBox,
      suggestions
    } = useAutocompleteWithParams<Zone>({
      service: zonesService,

      params: {
        zone_type: {
          value: zoneType.value, matchMode: FilterMatchMode.EQUALS
        }
      },

      getQueryOpts: () => {
        return { customer_id: customerId.value }
      },

      afterSelect: (zone: Zone) => {
        emit("select", zone);
      },

      afterClear: () => {
        emit("clear");
      }
    });

    watch(
      modelValue,
      () => {
        searchBox.value = modelValue.value;
      },
      {
        immediate: true
      }
    );

    return {
      searchBox,
      suggestions,
      onSelect,
      onClear,
      search,
    };
  },
});
